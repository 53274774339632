import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FindInArray from '../../../utils/FindInArray';
import StatusBtn from '../../../utils/StatusBtn2';

const ShowButton = ({ moreCount, setmoreCount, itemLength }) => {
    if (moreCount < 4 && itemLength > 3) {
        return (
            <div className="user-order-section-show-more">
                <button
                    type="button"
                    onClick={() => {
                        setmoreCount(itemLength);
                    }}
                >
                    Show More
                </button>
            </div>
        );
    }
    if (moreCount > 4) {
        return (
            <div className="user-order-section-show-more">
                <button
                    type="button"
                    onClick={() => {
                        setmoreCount(3);
                    }}
                >
                    Show Less
                </button>
            </div>
        );
    }
    return null;
};
function UserOrdersSection({ memoData, memoItemData, products }) {
    const [moreCount, setmoreCount] = useState(3);
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');
    console.log(memoData);
    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-6 user-order-border-bottom">
                    <h5 className="user-order-section-number">
                        Order Id: #
                        <Link to={`/user/order-detail/${memoData.id}`}>INV {memoData.id}</Link>
                    </h5>
                    <h6 className="user-order-section-time">Placed on {memoData.time_created}</h6>
                </div>
                <div className="col-12 col-lg-6 user-order-border-bottom2">
                    <Link
                        to={`/user/order-detail/${memoData.id}/`}
                        className="ml-3 float-right user-order-section-btn-detail"
                        style={{ paddingTop: 7 }}
                    >
                        Detail
                    </Link>

                    {memoData.payment_status !== 'due' ? (
                        <button type="button" className="float-right user-order-section-btn-review">
                            Write a Review
                        </button>
                    ) : (
                        <Link
                            to={`/invoice/${memoData.id}/payment/`}
                            className="ml-3 float-right user-order-section-btn-detail-pay"
                            style={{ paddingTop: 7 }}
                        >
                            Payment Now
                        </Link>
                    )}
                </div>
                <div className="col-12">
                    {memoItemData &&
                        memoItemData.slice(0, moreCount).map((item) => {
                            const itemData = FindArr.find(item.productId);
                            return (
                                <div className="row bottom-border" key={item.id}>
                                    <div className="col-4 col-lg-2">
                                        <div className="user-orders-img">
                                            <img
                                                src={`https://www.optimizee.xyz/images/${btoa(
                                                    itemData.default_image
                                                )},200,300.jpg`}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-8 col-lg-3">
                                        <div className="order-section-detail-title">
                                            <Link to={item.url}>
                                                <b>{item.product_title}</b>
                                            </Link>
                                        </div>

                                        {/* <br />
                                <ul className="variant-list">
                                    <CartProductVariant
                                        data={Object.values(item.variation).slice(
                                            0,
                                            Object.keys(item.variation).length - 2
                                        )}
                                    />
                                </ul> */}
                                    </div>
                                    <div className="col-1 order-viewQty">x{item.quantity}</div>
                                    <div className="col-2 order-viewStatus">
                                        <StatusBtn status={memoData.status} />
                                    </div>
                                    <div className="col-4 order-viewStatus">
                                        <div className="order-estimated-time">
                                            Estimated Time:
                                            <br /> Sat 25 Sep - Sun 26 Sep
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <ShowButton
                moreCount={moreCount}
                setmoreCount={setmoreCount}
                itemLength={memoItemData.length}
            />
        </>
    );
}
export default UserOrdersSection;
