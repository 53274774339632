/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import Footer from '../../system/components/Footer';
import HeaderSection from '../../system/components/HeaderSection';
import CategoryPageFilter from '../components/categories/CategoryPageFilter';
import CategoryPageSidebar from '../components/categories/CategoryPageSidebar';
import CategoryProducts from '../components/categories/CategoryProducts';

function CategoryPage() {
    const { shopData, products } = InitInfo();

    const dispatch = useDispatch();
    const productData = products;
    const productVariantList = shopData.productVariantList ? shopData.productVariantList : [];
    const history = useNavigate();
    const [categoryId, setCategoryId] = useState('');
    const [myClass, setMyClass] = useState('product-section-colI3');
    const [variants, setVariants] = useState([]);

    const handleToggle = (value) => () => {
        const newVariants = { ...variants };

        if (!variants[value]) {
            newVariants[value] = [];
        } else {
            delete newVariants[value];
        }

        setVariants(newVariants);
    };

    const handleToggleSub = (key, value) => () => {
        const currentIndex = variants[key]?.indexOf(value);
        const newVariants = { ...variants };

        if (currentIndex === -1) {
            newVariants[key].push(value);
        } else {
            newVariants[key]?.splice(currentIndex, 1);
        }

        setVariants(newVariants);
    };

    const handleColumThree = () => {
        setMyClass('product-section-colI3');
    };
    const handleColumOne = () => {
        setMyClass('product-section-colI1');
    };

    useEffect(() => {
        // effect

        AppInitDispatch(dispatch)
            .then(() => {
                setVariants({ size: Array(0), color: Array(0), ageRange: Array(0) });
            })
            .catch((err) => {
                console.log(err);
            });

        // return history.listen((location) => {
        //     setCategoryId(location.pathname.replace(/[^0-9]/g, ''));
        // });
        return setCategoryId(window.location.pathname.replace(/[^0-9]/g, ''));
    }, [dispatch, history]);

    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');

    return (
        <>
            <HeaderSection whiteMenu />
            <section className="PageSection">
                <Container>
                    <div className="page-banner" />

                    <div className="row">
                        <CategoryPageSidebar />
                        <div className="col-sm-12 col-lg-9 col-lg-9">
                            <div className="row row-m-product">
                                <CategoryPageFilter
                                    myClass={myClass}
                                    handleColumOne={handleColumOne}
                                    handleColumThree={handleColumThree}
                                />
                                {productData &&
                                    productData
                                        .filter((obj) =>
                                            obj.categories.find(
                                                (o) => o.toString() === categoryId.toString()
                                            )
                                        )
                                        .map((item) => {
                                            if (item) {
                                                return (
                                                    <CategoryProducts
                                                        itemData={item}
                                                        myClass={myClass}
                                                        key={item.id}
                                                    />
                                                );
                                            }
                                            return <></>;
                                        })}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default CategoryPage;
