import React from 'react';
import BuyNowButton from '../BuyNowButton';
import './effectOne.scss';

export default function EffectOne(props) {
    const { data } = props;
    return (
        <div className="portfolio-effect">
            <div className="portfolio-item portfolio-effect__item portfolio-item--eff4">
                <img
                    className="portfolio-item__image"
                    src={data ? data.default_image : ''}
                    alt="Portfolio Item"
                />

                <div className="portfolio-item__info">
                    <h3 className="portfolio-item__header">{data ? data.title : ''}</h3>

                    <div className="portfolio-item__links">
                        <BuyNowButton
                            variant="danger"
                            myClassName="feature-themeBtn"
                            size="xs"
                            title="SHOP NOW"
                            successMessage="Add to Cart"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
