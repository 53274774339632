/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import FindInArray from '../../../utils/FindInArray';
import StatusBtn from '../../../utils/StatusBtn';

function UserDashboardSectionDesktop({ products, memoItemAr, memoAr }) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');

    let sl = 0;
    return (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th>SL</th>
                    <th>Image</th>
                    <th>Product Image</th>
                    <th>Order Date</th>
                    <th>Status</th>
                    <th>Status Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {memoItemAr &&
                    memoItemAr?.map((item) => {
                        const product = FindArr.find(item.productId);
                        const memoAr2 = memoAr.filter(
                            (mItem) => mItem.id.toString() === item.memoId.toString()
                        )[0];
                        sl += 1;

                        return (
                            <tr key={item.id}>
                                <td>{sl}</td>
                                <td>
                                    {' '}
                                    <img
                                        src={product?.default_image}
                                        alt=""
                                        style={{ width: 40 }}
                                    />
                                </td>
                                <td>{item.product_title}</td>
                                <td>{memoAr2.time_created}</td>
                                <td>
                                    <StatusBtn status={memoAr2.status} />
                                </td>
                                <td>{memoAr2.time_created}</td>
                                <td>
                                    <Link to={`/user/order-detail/${memoAr2.id}/`}>View Order</Link>
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
}

export default UserDashboardSectionDesktop;
