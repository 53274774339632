import { faCartArrowDown, faSortDown, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BuyNowButton from '../../../utils/BuyNowButton';
import ProductVariantModal from './ProductVariantModal';

function Rating({ color, detail }) {
    const handleRatingDetail = () => {
        //
    };

    return (
        <>
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            <FontAwesomeIcon icon={faStar} color={color || 'gray'} />
            {detail === true && (
                <FontAwesomeIcon icon={faSortDown} onMouseOver={handleRatingDetail} />
            )}
        </>
    );
}

function BuyNowWithVariation({ myClassName, icon, title, size, productData }) {
    const [modalShow, setModalShow] = useState(false);

    const handleModalClose = () => setModalShow(false);

    const handleModalShow = () => {
        setModalShow(true);
    };

    return (
        <>
            <Button
                variant="outline-info"
                className={[myClassName || 'ThemeBtn'].join(' ')}
                size={size || 'sm'}
                onClick={handleModalShow}
            >
                {icon && <FontAwesomeIcon icon={icon} />}
                {icon ? ' ' : ''} {title || 'Button'}
            </Button>

            {modalShow && (
                <ProductVariantModal
                    modalShow={modalShow}
                    handleModalClose={handleModalClose}
                    productData={productData}
                />
            )}
        </>
    );
}
export default function ProductPageSingle(props) {
    const { itemData } = props;
    return (
        <Card className="product-section-card">
            <Card.Body className="product-section-card-body">
                <div className="row">
                    <div className="col-5">
                        <Link to={itemData.url}>
                            <Card.Img
                                className="product-section-imgBox"
                                src={`https://www.optimizee.xyz/images/${btoa(
                                    itemData.default_image
                                )},200,300.jpg`}
                            />
                        </Link>
                    </div>
                    <div className="col-4" style={{ borderLeft: '1px solid #ff000033' }}>
                        <Card.Text className="product-section-title-text">
                            <Link to={itemData.url}>{itemData.title}</Link>
                        </Card.Text>
                        <div className="product-section-pricing">
                            <div>
                                <h6>Price:</h6>
                                <strong>৳ {itemData.priceOffer}</strong>
                            </div>

                            <div style={{ float: 'left', marginTop: '30px' }}>
                                {itemData.variation_header !== null ? (
                                    <BuyNowWithVariation
                                        myClassName="product-section-buyNow"
                                        icon={faCartArrowDown}
                                        size="sm"
                                        title="BUY NOW"
                                        productId={itemData.id}
                                        productData={itemData}
                                    />
                                ) : (
                                    <BuyNowButton
                                        myClassName="product-section-buyNow"
                                        icon={faCartArrowDown}
                                        size="sm"
                                        title="BUY NOW"
                                        productId={itemData.id}
                                        selectedVariation={{}}
                                        isDisable={false}
                                        shopId={itemData.shopSl}
                                        newQuantity={1}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div
                            className="product-section-rating"
                            style={{ float: 'right', marginTop: '15px' }}
                        >
                            <h6>Rating:</h6>
                            <Rating />
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}
