import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    GithubAuthProvider,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    TwitterAuthProvider,
    // eslint-disable-next-line prettier/prettier
    updateProfile
} from 'firebase/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';
import ConfigApi from '../../configs/ConfigApi';
import UserLoginModal from '../app/users/auth/login/UserLoginModal';
import ForgetPasswordModal from '../app/users/auth/password/ForgetPasswordModal';
import UserRegistrationModal from '../app/users/auth/registration/UserRegistrationModal';
import { firebaseAuth } from '../MyFirebase';
import AxiosAuth from '../utils/AxiosAuth';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modalType, setModalType] = useState(null); // sign-in, sign-up, forget-password
    const [modalProperty, setModalProperty] = useState({
        onSuccessLogin: null,
        onCloseClick: null,
        openModalWhenNoUser: false,
    });
    const [otpConfig, setOtpConfig] = useState(null);

    const signUp = (email, password) =>
        createUserWithEmailAndPassword(firebaseAuth, email, password);

    const signIn = (email, password) => signInWithEmailAndPassword(firebaseAuth, email, password);

    function logout() {
        return signOut(firebaseAuth);
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(firebaseAuth, email);
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email);
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password);
    }

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('profile');
        provider.addScope('email');
        provider.addScope('openid');

        return signInWithPopup(firebaseAuth, provider);
    };

    const signInWithFacebook = () => {
        const provider = new FacebookAuthProvider();
        provider.addScope('public_profile');
        provider.addScope('email');
        return signInWithPopup(firebaseAuth, provider);
    };

    const signInWithGithub = () => {
        const provider = new GithubAuthProvider();
        return signInWithPopup(firebaseAuth, provider);
    };

    const signInWithTwitter = () => {
        const provider = new TwitterAuthProvider();
        return signInWithPopup(firebaseAuth, provider);
    };

    const openLoginModal = (onSuccessLogin, onCloseClick) => {
        if (!currentUser?.userFound) {
            setModalType('sign-in');
            setModalProperty({ onSuccessLogin, onCloseClick });
        }
    };

    const openRegistrationModal = (onSuccessRegistration, onCloseClick) => {
        if (!currentUser?.userFound) {
            setModalType('sign-up');
            setModalProperty({ onSuccessRegistration, onCloseClick });
        }
    };
    const openForgetPasswordModal = (onSuccessChangePassword, onCloseClick) => {
        if (!currentUser?.userFound) {
            setModalType('forget-password');
            setModalProperty({ onSuccessChangePassword, onCloseClick });
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            const providerData = user?.providerData || [];

            const emptyUser = {
                userFound: false,
                provider: null,
                localProvider: null,
                displayName: null,
                nickName: null,
                email: null,
                phoneNumber: null,
                photoUrl: null,
                uid: null,
                localUid: null,
                refreshToken: null,
            };

            if (providerData.length > 0) {
                AxiosAuth.post(`${ConfigApi.API_LOGIN}firebase-login/`, {
                    uid: user.uid,
                    refreshToken: user.refreshToken,
                })
                    .then(({ data }) => {
                        if (data.otpRequired) {
                            // --OTP Required
                            setOtpConfig(true);
                        } else if (data.error) {
                            // --Login Error
                            setCurrentUser(emptyUser);
                        } else {
                            // --Login Success
                            const cUser = {
                                userFound: true,
                                provider: providerData[0].providerId,
                                nickName: providerData[0].displayName,
                                email: data.user.default_email,
                                phoneNumber: data.user.default_contact,
                                uid: providerData[0].uid,
                                localProvider: user.providerId,
                                localUid: user.uid,
                                photoUrl: data.user.photo_url,
                                displayName: data.user.name,
                                refreshToken: user.refreshToken,
                            };

                            setCurrentUser(cUser);
                            setModalType(null);

                            if (typeof modalProperty.onSuccessLogin === 'function') {
                                modalProperty.onSuccessLogin(cUser);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setCurrentUser(emptyUser);
                    });
            } else {
                setCurrentUser(emptyUser);
            }

            setLoading(false);
        });

        return unsubscribe;
    }, [modalProperty]);

    const isSignIn = currentUser && currentUser?.userFound;

    const value = {
        currentUser,
        signUp,
        signIn,
        signOut: logout,
        resetPassword,
        updateEmail,
        updatePassword,
        openLoginModal,
        openRegistrationModal,
        isSignIn,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}

            <UserLoginModal
                currentUser={currentUser}
                show={modalType === 'sign-in'}
                setShow={(mode) => {
                    setModalType(mode ? 'sign-in' : null);
                }}
                signIn={signIn}
                signInWithGoogle={signInWithGoogle}
                signInWithFacebook={signInWithFacebook}
                signInWithTwitter={signInWithTwitter}
                onCloseClick={modalProperty.onCloseClick}
                otpConfig={otpConfig}
                openRegistrationModal={openRegistrationModal}
                openForgetPasswordModal={openForgetPasswordModal}
            />

            <ForgetPasswordModal
                currentUser={currentUser}
                show={modalType === 'forget-password'}
                setShow={(mode) => {
                    setModalType(mode ? 'forget-password' : null);
                }}
                returnToLogin={() => {
                    setModalType('sign-in');
                }}
                onCloseClick={modalProperty.onCloseClick}
            />

            <UserRegistrationModal
                signIn={signIn}
                signUp={signUp}
                updateProfile={updateProfile}
                currentUser={currentUser}
                show={modalType === 'sign-up'}
                setShow={(mode) => {
                    setModalType(mode ? 'sign-up' : null);
                }}
                signInWithGoogle={signInWithGoogle}
                signInWithFacebook={signInWithFacebook}
                signInWithGithub={signInWithGithub}
                signInWithTwitter={signInWithTwitter}
                onCloseClick={modalProperty.onCloseClick}
                otpConfig={otpConfig}
                openLoginModal={openLoginModal}
            />
        </AuthContext.Provider>
    );
}
