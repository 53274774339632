import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import HeaderSection from '../components/HeaderSection';

function AboutUsPage() {
    return (
        <>
            <HeaderSection whiteMenu />
            <section className="product-detail-section">
                <Container>
                    <p style={{ minHeight: 300 }}>
                        RUPKATHA, the trendiest fashion brand from Bangladesh, is mostly
                        distinguished for its true international quality designs and fabrics. We are
                        inspired by our customers- souls full of unconventional fashion senses. As a
                        wholesaler we started our journey in 2015 and now we have 7 wholesale stores
                        across Bangladesh and a 24/7 online store for retail. Since origin, we have
                        been offering world class designs at amazing value price. Our product line
                        includes a wide range of fashion cloths for men, women and children.
                        RUPKATHA dreams to be an international brand icon in the domain of fashion.
                    </p>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default AboutUsPage;
