import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import Footer from '../../system/components/Footer';
import HeaderSection from '../../system/components/HeaderSection';
import CategoryPageFilter from '../components/categories/CategoryPageFilter';
import CategoryPageSidebar from '../components/categories/CategoryPageSidebar';
import CategoryProducts from '../components/categories/CategoryProducts';

function CategoryPageAll() {
    const { products } = InitInfo();

    const productData = products;
    const [myClass, setMyClass] = useState('product-section-colI3');

    const handleColumThree = () => {
        setMyClass('product-section-colI3');
    };
    const handleColumOne = () => {
        setMyClass('product-section-colI1');
    };

    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');

    return (
        <>
            <HeaderSection whiteMenu />
            <section className="PageSection">
                <Container>
                    <div className="page-banner" />

                    <div className="row">
                        <CategoryPageSidebar />
                        <div className="col-sm-12 col-lg-9 col-lg-9">
                            <div className="row row-m-product">
                                <CategoryPageFilter
                                    myClass={myClass}
                                    handleColumOne={handleColumOne}
                                    handleColumThree={handleColumThree}
                                />
                                {productData &&
                                    productData?.map((item) => {
                                        if (item) {
                                            return (
                                                <CategoryProducts
                                                    itemData={item}
                                                    myClass={myClass}
                                                    key={item.id}
                                                />
                                            );
                                        }
                                        return <></>;
                                    })}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default CategoryPageAll;
