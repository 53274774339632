/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconSetting from '../../../../assets/images/IconDashSetting.svg';
import IconToPay from '../../../../assets/images/IconToPay.svg';
import IconToReceive from '../../../../assets/images/IconToReceive.svg';
import IconToReview from '../../../../assets/images/IconToReview.svg';
import IconToShip from '../../../../assets/images/IconToShip.svg';
import IconCancel from '../../../../assets/images/IconUserCancel.svg';
import IconHelp from '../../../../assets/images/IconUserHelp.svg';
import IconPayment from '../../../../assets/images/IconUserPay.svg';
import IconReturn from '../../../../assets/images/IconUserReturn.svg';
import ImgPlace from '../../../../assets/images/placeholder-image.png';
import FindInArray from '../../../utils/FindInArray';
import StatusBtn from '../../../utils/StatusBtn';

function CarouselItem() {
    return (
        <Carousel.Item>
            <div className="user-dashboard-section-package">
                <div className="user-dashboard-section-package-img">
                    <img src={ImgPlace} alt="" />
                </div>
                <div className="user-dashboard-section-package-detail">
                    <p>
                        <StatusBtn status="delivered" />
                    </p>
                    <p>12:24 Feb 22</p>
                    <span>Your product have been delivered. Leave your Review here</span>
                </div>
            </div>
        </Carousel.Item>
    );
}

function UserDashboardSection({ currentUser, products, memoItemAr, memoAr }) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');

    return (
        <>
            <div className="user-dashboard-section">
                <div className="user-dashboard-section-user">
                    <img src={currentUser.photoUrl} alt="" />
                    <p>
                        {currentUser.displayName}
                        <br />
                        <small>{currentUser.email}</small>
                    </p>
                </div>
                <div className="user-dashboard-section-setting">
                    <Link to="/user/profile/">
                        <img src={IconSetting} alt="" />
                    </Link>
                </div>
            </div>
            <div className="user-dashboard-section-summary">
                <div className="">
                    <h6>12</h6>
                    <h6>Wishlist</h6>
                </div>
                <div className="">
                    <h6>8</h6>
                    <h6>Voucher</h6>
                </div>
                <div className="">
                    <h6>৳1974</h6>
                    <h6>Wallet</h6>
                </div>
            </div>
            <div className="user-dashboard-section-order">
                <div className="user-dashboard-section-order-title">
                    <h6>My Order</h6>
                    <Link to="/user/order">View All ></Link>
                </div>
                <div className="user-dashboard-section-order-option">
                    <Link to="/user/order">
                        <img src={IconToPay} alt="" />
                        <p>To pay</p>
                    </Link>
                    <Link to="/user/order">
                        <img src={IconToShip} alt="" />
                        <p>To Ship</p>
                    </Link>
                    <Link to="/user/order" style={{ textAlign: 'center' }}>
                        <img src={IconToReceive} alt="" style={{ marginLeft: 0 }} />
                        <p>To Receive</p>
                    </Link>
                    <Link to="/user/order">
                        <img src={IconToReview} alt="" style={{ marginRight: 16 }} />
                        <p>To Review</p>
                    </Link>
                </div>
            </div>
            <div className="user-dashboard-section-order-track">
                <div className="user-dashboard-section-order-title">
                    <h6>Track Package</h6>
                </div>
                <div className="user-dashboard-section-order-option">
                    <Carousel variant="dark" controls={false}>
                        {memoAr.length !== 0 ? (
                            memoAr &&
                            memoAr.map((item) => {
                                const memoItem = memoItemAr.filter(
                                    (mItem) => mItem.memoId.toString() === item.id.toString()
                                );
                                const itemData = FindArr.find(memoItem[0]?.productId);
                                return (
                                    <Carousel.Item>
                                        <div className="user-dashboard-section-package">
                                            <div className="user-dashboard-section-package-img">
                                                <img src={itemData?.default_image} alt="" />
                                            </div>
                                            <div className="user-dashboard-section-package-detail">
                                                <p>
                                                    <StatusBtn status={item.status} />
                                                </p>
                                                <p>
                                                    <small>{item.time_created}</small>
                                                </p>
                                                <span>
                                                    Your product have been {item.status}. Leave your
                                                    Review here
                                                </span>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                );
                            })
                        ) : (
                            <div className="order-section-no-order">
                                <div className="order-section-no-order-title">
                                    <h5>There Are No Orders.</h5>
                                    <h6>You can review and track your orders here.</h6>
                                </div>
                            </div>
                        )}
                    </Carousel>
                </div>
            </div>
            <div className="user-dashboard-section-order">
                <div className="user-dashboard-section-order-title">
                    <h6>My Services</h6>
                </div>
                <div className="user-dashboard-section-order-option">
                    <Link to="/user/order">
                        <img src={IconPayment} alt="" />
                        <p>Payment</p>
                    </Link>
                    <Link to="/user/order">
                        <img src={IconReturn} alt="" />
                        <p>My Return</p>
                    </Link>
                    <Link to="/user/order" style={{ textAlign: 'center' }}>
                        <img src={IconCancel} alt="" style={{ marginLeft: 0 }} />
                        <p>Cancellation</p>
                    </Link>
                    <Link to="/user/order">
                        <img src={IconHelp} alt="" style={{ marginRight: 4 }} />
                        <p>Help</p>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default UserDashboardSection;
