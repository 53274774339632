/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import { faCartArrowDown, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
    FacebookShareButton,
    InstapaperShareButton,
    PinterestShareButton,
    // eslint-disable-next-line prettier/prettier
    TwitterShareButton
} from 'react-share';
import ReactTooltip from 'react-tooltip';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import BuyNowButton from '../../../../utils/BuyNowButton';

function ProductSize({ size, variants, itemName, handleToggleSub }) {
    const checked = variants[itemName] === size;
    return (
        <Button
            variant={checked ? 'danger' : 'outline-secondary'}
            className={['product-detail-sizeBtn', checked ? 'checked' : ''].join(' ')}
            onClick={handleToggleSub(itemName, size)}
        >
            {size}
        </Button>
    );
}

function ProductAgeRange({ age, variants, itemName, handleToggleSub }) {
    const checked = variants[itemName] === age;
    return (
        <Button
            size="sm"
            variant={checked ? 'danger' : 'outline-secondary'}
            className={['product-detail-ageBtn mr-2', checked ? 'checked' : ''].join(' ')}
            onClick={handleToggleSub(itemName, age)}
        >
            {age}
        </Button>
    );
}

function ProductColor({ color, variants, itemName, handleToggleSub }) {
    const checked = variants[itemName] === color;
    return (
        <div
            className={['color-item', checked ? 'checked' : ''].join(' ')}
            style={{ border: checked ? `1px solid ${color}` : `1px solid transparent` }}
        >
            <button
                type="button"
                style={{
                    backgroundColor: color,
                }}
                onClick={handleToggleSub(itemName, color)}
                className={checked ? 'color-item-bg' : ''}
            />
        </div>
    );
}

function ShowProductDetailsHeader({ data }) {
    return (
        <div className="product-detail-header">
            <strong className="product-detail-title">{data.title}</strong>
            <br />
            {data.reward && (
                <small style={{ color: 'yellowgreen' }}>
                    You will get {(data.reward * data.price_purchase) / 100} Points
                </small>
            )}
            <div className="ProductDetailBorder" />
        </div>
    );
}

function ShowCategories({ data, productCategoryList }) {
    return (
        <div className="product-detail-category">
            {data.categories
                ? data.categories.map((cat) => {
                    const category = productCategoryList
                        .filter((e) => e.id === cat)
                        .map((en) => en.title);

                    return (
                        <button type="button" className="product-detail-category-btn" key={cat}>
                            {category} {data.categories.length > 1 ? "," : ''}
                        </button>
                    );
                })
                : 'Not Set'}
        </div>
    );
}

function ShowPrices({ data }) {
    console.log(data);
    return (
        <div className="product-detail-price">
            <div className="product-detail-price-title">
                <h6>Price :</h6>
            </div>
            <div className="product-detail-price-info">
                <strong>৳ {data.priceOffer}</strong>
                {data.price !== data.priceOffer ? <span>৳ {data.price}</span> : ''}

            </div>
            <div className="product-detail-price-info">
                <p>
                    <button type="button" />
                    In stock
                </p>
            </div>
            <hr />
        </div>
    );
}

function ProductVariantOption({ itemName, itemData, variants, handleToggleSub }) {
    if (itemName === 'color') {
        return (
            <div className="product-detail-color">
                <h6 className="product-detail-size-title">Color:</h6>
                {itemData.map((item) => (
                    <ProductColor
                        color={item}
                        key={item}
                        itemName={itemName}
                        variants={variants}
                        handleToggleSub={handleToggleSub}
                    />
                ))}
            </div>
        );
    }

    if (itemName === 'size') {
        return (
            <div className="product-detail-size">
                <h6 className="product-detail-size-title">Size:</h6>
                {itemData.map((item) => (
                    <ProductSize
                        size={item}
                        key={item}
                        itemName={itemName}
                        variants={variants}
                        handleToggleSub={handleToggleSub}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className="product-detail-age">
            <h6 className="product-detail-size-title">Age:</h6>
            {itemData.map((item) => (
                <ProductAgeRange
                    age={item}
                    key={item}
                    itemName={itemName}
                    variants={variants}
                    handleToggleSub={handleToggleSub}
                />
            ))}
        </div>
    );
}

function ProductVariant({ data, variants, handleToggleSub }) {
    if (data) {
        return Object.keys(data).map((item) => (
            <div key={item}>
                <ProductVariantOption
                    handleToggleSub={handleToggleSub}
                    itemName={item}
                    itemData={data[item]}
                    variants={variants}
                />
            </div>
        ));
    }

    return null;
}
function ShowShareButtons({ articleLink }) {
    return (
        <div className="product-detail-social-link">
            <ul>
                <li>
                    <FacebookShareButton url={articleLink} className="detail-share-fb" />
                </li>
                <li>
                    <TwitterShareButton url={articleLink} className="detail-share-tw" />
                </li>
                <li>
                    <PinterestShareButton url={articleLink} className="detail-share-pin" />
                </li>
                <li>
                    <InstapaperShareButton url={articleLink} className="detail-share-ins" />
                </li>
            </ul>
        </div>
    );
}

function QtyOption({ handleChange, handleQtyMinus, handleQtyPlus, newQuantity }) {
    return (
        <>
            <FontAwesomeIcon onClick={handleQtyMinus} size="sm" icon={faMinus} />
            <input
                style={{ border: 'none', width: '50px', textAlign: 'center' }}
                onChange={handleChange}
                value={newQuantity}
            />
            <FontAwesomeIcon onClick={handleQtyPlus} size="sm" icon={faPlus} />
        </>
    );
}

export default function ProductOverviewInfo({
    data,
    productCategoryList,
    shopId,
    isCatShow = true,
    handleModalClose,
}) {
    const [variants, setVariants] = useState({});
    const [newQuantity, setNewQuantity] = useState(1);
    const { setMessage } = useTemplate();
    const handleToggleSub = (key, value) => () => {
        setVariants({ ...variants, [key]: value });
        setNewQuantity(1);
    };
    const { currentUser } = useAuth();
    const handleQtyPlus = () => {
        setNewQuantity(newQuantity + 1);
    };
    const [isWish, setIsWish] = useState(false);

    const handleQtyMinus = () => {
        if (newQuantity <= 1) {
            setNewQuantity(1);
        } else {
            setNewQuantity(newQuantity - 1);
        }
    };

    const handleChange = (e) => {
        setNewQuantity(e.target.value);
    };

    const handleWishList = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(ConfigApi.API_WISHLIST_CREATE.replace(':productSl', data.id))
            .then((response) => {
                setMessage(response.data.message);
                if (response.data.error === 0) {
                    setIsWish(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const articleLink = `https://live.rupkotha.sourav.xyz/products/${data.url}`
    useEffect(() => {
        if (currentUser) {
            AxiosAuth.currentUserAuth(currentUser)
                .get(ConfigApi.API_PRODUCT_WISHLIST.replace(':productId', data.id))
                .then((response) => {
                    setIsWish(response.data.isWish);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [currentUser, data.id]);

    return (
        <>
            <ShowProductDetailsHeader data={data} />
            {isCatShow ? (
                <ShowCategories data={data} productCategoryList={productCategoryList} />
            ) : null}

            <ShowPrices data={data} />

            <ProductVariant
                handleToggleSub={handleToggleSub}
                variants={variants}
                data={data?.variation_header || {}}
                handleChange={handleChange}
            />

            <div>
                <div className="product-detail-btn-group">
                    <div className="product-qty-btn">
                        <QtyOption
                            handleChange={handleChange}
                            handleQtyMinus={handleQtyMinus}
                            handleQtyPlus={handleQtyPlus}
                            newQuantity={newQuantity}
                        />
                    </div>

                    <div className="product-cart-btn" data-tip data-for="varia">
                        <BuyNowButton
                            myClass="testbtn"
                            icon={faCartArrowDown}
                            size="md"
                            title="ADD TO CART"
                            id={data.id}
                            selectedVariation={variants}
                            isDisable={
                                Object.keys(data?.variation_header || []).length !==
                                Object.keys(variants).length
                            }
                            shopId={shopId}
                            productId={data.id}
                            newQuantity={newQuantity}
                            successMessage="Add to Cart"
                            handleModalClose={handleModalClose}
                        />
                    </div>
                    <button
                        type="button"
                        className={isWish ? 'product-fav-btn-white' : 'product-fav-btn'}
                        onClick={handleWishList}
                    />
                    {isCatShow ? (
                        <ShowShareButtons articleLink={articleLink} />
                    ) : null}
                </div>
            </div>

            {Object.keys(data?.variation_header || []).length !== Object.keys(variants).length ? (
                <ReactTooltip type="error" effect="solid">
                    <span>Select Variation</span>
                </ReactTooltip>
            ) : null
            }
        </>
    );
}
