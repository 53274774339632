import React from 'react';
import { Container, Table } from 'react-bootstrap';
// import IconBank from '../../../../assets/images/IconBank.svg';
// import IconBkash from '../../../../assets/images/IconBkash.svg';
// import IconCashOn from '../../../../assets/images/IconCashOut.svg';
// import IconNagad from '../../../../assets/images/IconNagad.svg';
import { usePayment } from '../../../contexts/PaymentProvider';
import AddressTypeBtn from '../../../utils/AddressTypeBtn';

function PaymentOptions({ paymentOptions, selectedPayment, setSelectedPayment }) {
    return (
        <div className="col-12 col-lg-8 mb-4">
            <div className="cart-section-card card">
                <div className="card-body">
                    <div className="payment-section-title">Payment Method</div>
                    <div className="payment-section-option">
                        {paymentOptions.map((item) => {
                            if (item.isEnable)
                                return (
                                    <button
                                        type="button"
                                        // className="disable-btn"
                                        className={` 
                                        ${selectedPayment?.id === item?.id ? 'active' : ''}`}
                                        key={item.id}
                                        onClick={() => {
                                            setSelectedPayment(item);
                                        }}
                                    >
                                        <div className="payment-section-option-item">
                                            <img src={item.icon} alt={item.title} />
                                            <p>{item.title}</p>
                                        </div>
                                    </button>
                                );

                            return null;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

function CheckoutAddress({ selectedAddress }) {
    return (
        <>
            <div className="checkout-summary-top">
                <p>{selectedAddress?.name}</p>
            </div>
            <div className="checkout-summary-address">
                <AddressTypeBtn type={selectedAddress?.resident_type} />
                <p>
                    {[selectedAddress?.line1, selectedAddress?.line2]
                        .filter((item) => !!item)
                        .join(', ')}
                    <br />
                    {[
                        selectedAddress?.city,
                        selectedAddress?.state,
                        selectedAddress?.country,
                        selectedAddress?.zip,
                    ]
                        .filter((item) => !!item)
                        .join(', ')}
                    <br />
                </p>
            </div>
            <div className="checkout-summary-top">
                <p>Bill to the same address</p>
            </div>
            <div className="checkout-summary-top">
                <p>{selectedAddress?.phone}</p>
            </div>
            <div className="checkout-summary-top">
                <p>{selectedAddress?.email}</p>
            </div>
        </>
    );
}

function PaymentSummary({ invoiceInfo, paymentClickHandler, selectedPayment }) {
    const { addresses, totalQuantity, totalAmountOffer, shippingCharge } = invoiceInfo;
    const { shipping } = addresses;

    return (
        <div className="col-12 col-lg-4 mb-4">
            <div className="cart-section-card card">
                <div className="card-body">
                    <div className="checkout-summary-title"> Shipping & Billing</div>
                    <CheckoutAddress selectedAddress={shipping} />

                    <div className="checkout-summary-divider" />
                    <div className="checkout-summary-title"> Order Summary</div>
                    <div className="cart-section-summary">
                        <Table>
                            <tbody>
                                <tr>
                                    <td>Subtotal ({totalQuantity} items)</td>
                                    <td className="text-right">৳ {totalAmountOffer.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>Shipping Fee</td>
                                    <td className="text-right">৳ {shippingCharge.toFixed(2)}</td>
                                </tr>
                                <tr style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    <td>Total</td>
                                    <td className="text-right">
                                        ৳ {(totalAmountOffer + shippingCharge).toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <button
                            type="button"
                            className="proceed-to-pay"
                            onClick={paymentClickHandler}
                        >
                            {selectedPayment ? selectedPayment.btnText : 'Pay Now'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
function PaymentPagePageSection({ invoiceData }) {
    const { invoiceInfo } = invoiceData;
    const { paymentOptions, selectedPayment, setSelectedPayment, paymentClickHandler } =
        usePayment();

    return (
        <section className="cart-section">
            <Container>
                <div className="row">
                    <PaymentOptions
                        paymentOptions={paymentOptions}
                        selectedPayment={selectedPayment}
                        setSelectedPayment={setSelectedPayment}
                    />
                    <PaymentSummary
                        invoiceInfo={invoiceInfo}
                        paymentClickHandler={paymentClickHandler}
                        selectedPayment={selectedPayment}
                    />
                </div>
            </Container>
        </section>
    );
}

export default PaymentPagePageSection;
