import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FindInArray from '../../../utils/FindInArray';

const ShowButton = ({ moreCount, setmoreCount, itemLength }) => {
    if (moreCount < 4 && itemLength > 3) {
        return (
            <div className="user-order-section-show-more">
                <button
                    type="button"
                    onClick={() => {
                        setmoreCount(itemLength);
                    }}
                >
                    Show More
                </button>
            </div>
        );
    }
    if (moreCount > 4) {
        return (
            <div className="user-order-section-show-more">
                <button
                    type="button"
                    onClick={() => {
                        setmoreCount(3);
                    }}
                >
                    Show Less
                </button>
            </div>
        );
    }
    return null;
};
function ReviewStar({ currentReview }) {
    const rows = [];
    for (let i = 1; i <= 5; i += 1) {
        if (i <= currentReview) {
            rows.push(<FontAwesomeIcon size="lg" icon={faStar} color="#FFC01D" />);
        } else {
            rows.push(<FontAwesomeIcon size="lg" icon={faStar} color="#CFD1D5" />);
        }
    }
    return rows;
}
function UserReviewSectionComplete({ memoData, memoItemData, products }) {
    const [moreCount, setmoreCount] = useState(3);
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');
    console.log(memoItemData);
    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-6 user-order-border-bottom">
                    <h5 className="user-order-section-number">
                        Order Id: #
                        <Link to={`/user/order-detail/${memoData.id}`}>INV {memoData.id}</Link>
                    </h5>
                </div>
                <div className="col-12 col-lg-6 user-order-border-bottom2">
                    <h6 className="user-order-section-time" style={{ textAlign: 'right' }}>
                        Delivered on {memoData.time_created}
                    </h6>
                </div>
                <div className="col-12">
                    {memoItemData &&
                        memoItemData?.slice(0, moreCount)?.map((item) => {
                            const itemData = FindArr.find(item.productId);
                            return (
                                <div className="row bottom-border" key={item.id}>
                                    <div className="col-4 col-lg-2">
                                        <div className="user-orders-img">
                                            <img
                                                src={`https://www.optimizee.xyz/images/${btoa(
                                                    itemData.default_image
                                                )},200,300.jpg`}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-8 col-lg-4">
                                        <div className="order-section-detail-title">
                                            <Link to={item.url}>
                                                <b>{item.product_title}</b>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-1 order-viewQty">x{item.quantity}</div>
                                    <div className="col-3 order-viewQty">
                                        <ReviewStar currentReview={item.point} />
                                    </div>

                                    <div className="col-2">
                                        <Link
                                            to={`/user/reviews/${item.id}/detail/complete`}
                                            className="ml-3 float-right user-order-section-btn-detail"
                                            style={{ paddingTop: 7 }}
                                        >
                                            Detail
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <ShowButton
                moreCount={moreCount}
                setmoreCount={setmoreCount}
                itemLength={memoItemData.length}
            />
        </>
    );
}
export default UserReviewSectionComplete;
