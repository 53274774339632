import React from 'react';
import InitInfo from '../../../utils/InitInfo';
import BestSellProducts from '../components/BestSellProducts';
import ExclusiveProductSection from '../components/ExclusiveProductSection';
import FeatureProductSection from '../components/FeatureProductSection';
import Footer from '../components/Footer';
// General Parameter of Each Page
// 1. Title Set
// 2. Data Load (From DB) In a single File
// 3. Spinner until load Data
import HeaderSection from '../components/HeaderSection';
import NewArrivalProductSection from '../components/NewArrivalProductSection';
import SelectedProductSection from '../components/SelectedProductSection';
import SliderSection from '../components/SliderSection';

export default function IndexPage() {
    const { products } = InitInfo();

    return (
        <>
            <HeaderSection whiteMenu={false} />

            <SliderSection />

            <FeatureProductSection />

            <SelectedProductSection products={products} />

            <ExclusiveProductSection products={products} />

            <NewArrivalProductSection products={products} />

            <BestSellProducts products={products} />

            <Footer />
        </>
    );
}
