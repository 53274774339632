import React from 'react';
import Footer from '../components/Footer';
import HeaderSection from '../components/HeaderSection';
import TermsSection from '../components/TermsSection';

export default function TermsPage() {
    return (
        <>
            <HeaderSection whiteMenu />
            <TermsSection />
            <Footer />
        </>
    );
}
