import {
    faFacebookF,
    faInstagram,
    faTwitter,
    // eslint-disable-next-line prettier/prettier
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { faAt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import CartIcon from '../../../../assets/images/IconFCart.svg';
// import HomeIcon from '../../../../assets/images/IconFHome.svg';
// import NotiIcon from '../../../../assets/images/IconFNoti.svg';
// import UserIcon from '../../../../assets/images/IconFProfile.svg';
import rupkathaLogo from '../../../../assets/images/Rup-logo-white.svg';
import SendIcon from '../../../../assets/images/send-icon.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import { useCart } from '../../../contexts/CartProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';

function Footer() {
    const { totalItem } = useCart();
    const [email, setEmail] = useState();
    // const { currentUser } = useAuth();
    const { setMessage } = useTemplate();

    // const { pathname } = useLocation();
    const handleEmail = (ev) => {
        setEmail(ev.target.value);
    };
    const hnadleSubscribe = () => {
        AxiosAuth.post(ConfigApi.API_EMAIL_SUBSCRIBE, {
            email,
        })
            .then((response) => {
                setMessage(response.data.message);
                if (response.data.error === 0) {
                    setEmail('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            <section className="footer-section">
                <Container>
                    <div className="footer-section-item">
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <div className="overflow-auto">
                                    <Link to="/">
                                        <img
                                            className="footer-section-logo"
                                            src={rupkathaLogo}
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="footer-section-address">
                                    13,DIT ROAD, MALIBAGH CHOWDHURYPARA ( 4th & 5th floor) 1219
                                    DHAKA,
                                </div>
                                <div className="footer-section-contact-list">
                                    <button type="button">
                                        <FontAwesomeIcon icon={faPhone} />
                                    </button>
                                    <a href="tel:880 2 222245646">+880 2 222245646-7</a>
                                </div>
                                <div className="footer-section-contact-list">
                                    <button type="button">
                                        <FontAwesomeIcon icon={faAt} />
                                    </button>
                                    <a href="mailto:rupkotha@gmail.com">rupkotha@gmail.com</a>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="footer-section-quick-link">
                                    <h4>Quick Link</h4>
                                    <div className="footer-section-quick-menu">
                                        <ul>
                                            <li>
                                                <Link to=".">Men</Link>
                                            </li>
                                            <li>
                                                <Link to=".">Women</Link>
                                            </li>
                                            <li>
                                                <Link to=".">Kids</Link>
                                            </li>
                                            <li>
                                                <Link to="/about-us/">About Us</Link>
                                            </li>
                                            <li>
                                                <Link to=".">Contact Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/terms-condition/">
                                                    Terms & Condition
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="footer-section-social-link">
                                    <div className="footer-section-news">
                                        <h3>Our Newsletter</h3>
                                        <div className="footer-section-news-divider" />
                                        <p>
                                            Subscribe to our Newsletter and get an update from us in
                                            realtime
                                        </p>
                                        <div className="footer-section-subscribe-input-group">
                                            <input
                                                type="email"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={handleEmail}
                                            />
                                            <button type="button" onClick={hnadleSubscribe}>
                                                <img src={SendIcon} alt="Send Icon" />{' '}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="footer-section-social-link-item">
                                        <a
                                            href="https://www.facebook.com/Rupkatha.com.bd"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </a>

                                        <a
                                            href="https://twitter.com/rupkath85231111?fbclid=IwAR2rOQJ_Y0HyNCgOHNJKAlOG_eDmmfPCftoZxSpG9jEeomjg40rmFZavaSg"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </a>

                                        <a
                                            href="https://www.youtube.com/channel/UC-56AWPhbB_bGp7HpTgVxeQ"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faYoutube} />
                                        </a>

                                        <a
                                            href="https://www.instagram.com/rupkatha.ia/?fbclid=IwAR1oz7nRpTsTxt4qEfocpM_VtYu8Udfiw4Ich_qbKRXM3efFOkPJp6Wcxq4"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </a>

                                        {/* <a href="." target="_blank">
                                            <FontAwesomeIcon icon={faFlickr} />
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="footer-section-copy-right">
                    Copyright © 2022 Rupkotha. All rights reserved.
                </div>
            </section>
            <section className="footer-section-auto-height" />
            <section className="footer-section-mobile">
                <div className="footer-mobile">
                    <div className="container">
                        <div className="footer-mobile-menu">
                            <ul>
                                <li>
                                    <Link to="/">
                                        <div className="footer-mobile-home-icon" />
                                        <div>Home</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to=".">
                                        <div className="footer-mobile-noti-icon" />
                                        <div> Notification</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cart/">
                                        <div className="footer-mobile-cart-icon" />
                                        {totalItem > 0 && (
                                            <span className="footer-mobile-cart-item">
                                                {totalItem}
                                            </span>
                                        )}
                                        <div> Cart</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/user/dashboard/">
                                        <div className="footer-mobile-pro-icon" />
                                        <div> Account</div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="footer-navigation">
                            <ul>
                                <li className={pathname === '/' ? 'list active' : 'list'}>
                                    <Link to="/">
                                        <span className="icon">
                                            <img src={HomeIcon} alt="" />
                                        </span>
                                        <span className="text">Home</span>
                                    </Link>
                                </li>
                                <li className={pathname === '/noti' ? 'list active' : 'list'}>
                                    <Link to=".">
                                        <span className="icon">
                                            <img src={NotiIcon} alt="" />
                                        </span>
                                        <span className="text">Notification</span>
                                    </Link>
                                </li>
                                <li className={pathname === '/cart/' ? 'list active' : 'list'}>
                                    <Link to="/cart/">
                                        {totalItem > 0 && (
                                            <span className="footer-mobile-cart-item">
                                                {totalItem}
                                            </span>
                                        )}
                                        <span className="icon">
                                            <img src={CartIcon} alt="" />
                                        </span>
                                        <span className="text">Cart</span>
                                    </Link>
                                </li>
                                <li
                                    className={
                                        pathname === '/user/dashboard/' ? 'list active' : 'list'
                                    }
                                >
                                    <Link to="/user/dashboard/">
                                        <span className="icon">
                                            <img src={UserIcon} alt="" />
                                        </span>
                                        <span className="text">Account</span>
                                    </Link>
                                </li>
                                <div className="footer-indicator" />
                            </ul>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;
