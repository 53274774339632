/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    signInWithPopup,
    // eslint-disable-next-line prettier/prettier
    TwitterAuthProvider
} from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import { firebaseAuth } from '../../../MyFirebase';
import AxiosAuth from '../../../utils/AxiosAuth';
import UserEmailChnageModal from '../components/UserEmailChnageModal';
import UserPasswordChangeModal from '../components/UserPasswordChangeModal';
import UserPhoneChnageModal from '../components/UserPhoneChnageModal';
import UserProfilePageTitle from '../components/UserProfilePageTitle';
import UserUpdateModals from '../components/UserUpdateModals';

const EmailSection = ({ userInfo, setShowEmailmanage }) => (
    <div className="user-profile-show">
        <div className="row">
            <div className="col-8">
                <div className="user-profile-show-title">Email</div>
                <div className="user-profile-show-data">
                    <input
                        type="email"
                        name="emailChange"
                        readOnly
                        value={userInfo?.default_email || 'Not Set'}
                    />
                </div>
            </div>
            <div className="col-4">
                <div className="user-profile-show-btn">
                    <button
                        type="button"
                        onClick={() => {
                            setShowEmailmanage(true);
                        }}
                    >
                        Manage
                    </button>
                </div>
            </div>
        </div>
        <div className="user-profile-show-divider" />
    </div>
);

const NameSection = ({ userInfo, changeProfileBasic }) => (
    <div className="user-profile-show">
        <div className="row">
            <div className="col-8">
                <div className="user-profile-show-title">Name</div>
                <div className="user-profile-show-data">
                    <input
                        type="text"
                        readOnly
                        name="nameChange"
                        value={userInfo?.name || 'Not Set'}
                    />
                </div>
            </div>
            <div className="col-4">
                <div className="user-profile-show-btn">
                    <button
                        type="button"
                        onClick={() => {
                            changeProfileBasic('name');
                        }}
                    >
                        Change
                    </button>
                </div>
            </div>
        </div>
        <div className="user-profile-show-divider" />
    </div>
);

const PhoneNumberSection = ({ userInfo, setShowPhonemanage }) => (
    <div className="user-profile-show">
        <div className="row">
            <div className="col-8">
                <div className="user-profile-show-title">Phone Number</div>
                <div className="user-profile-show-data">
                    <input
                        type="text"
                        readOnly
                        name="contactChange"
                        value={userInfo?.default_contact || 'Not Set'}
                    />
                </div>
            </div>
            <div className="col-4">
                <div className="user-profile-show-btn">
                    <button
                        type="button"
                        onClick={() => {
                            setShowPhonemanage(true);
                        }}
                    >
                        Change
                    </button>
                </div>
            </div>
        </div>
        <div className="user-profile-show-divider" />
    </div>
);

const PasswordSection = ({ setShowPassChange }) => (
    <div className="user-profile-show">
        <div className="row">
            <div className="col-8">
                <div className="user-profile-show-title">Password</div>
                <div className="user-profile-show-data">
                    <input type="text" readOnly name="contactChange" value="******" />
                </div>
            </div>
            <div className="col-4">
                <div className="user-profile-show-btn">
                    <button
                        type="button"
                        onClick={() => {
                            setShowPassChange(true);
                        }}
                    >
                        Change
                    </button>
                </div>
            </div>
        </div>
        <div className="user-profile-show-divider" />
    </div>
);
const SocialSection = () => {
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('profile');
        provider.addScope('email');
        provider.addScope('openid');

        return signInWithPopup(firebaseAuth, provider);
    };

    const signInWithFacebook = () => {
        const provider = new FacebookAuthProvider();
        provider.addScope('public_profile');
        provider.addScope('email');
        return signInWithPopup(firebaseAuth, provider);
    };

    const signInWithTwitter = () => {
        const provider = new TwitterAuthProvider();
        return signInWithPopup(firebaseAuth, provider);
    };

    return (
        <div className="user-profile-show">
            <div className="row">
                <div className="col-12">
                    <Form.Group className="custom-login-social">
                        <Button
                            type="button"
                            onClick={() => {
                                signInWithGoogle();
                            }}
                            className="custom-login-btn-google"
                        >
                            Link with Google
                        </Button>
                        <Button
                            type="button"
                            className="custom-login-btn-facebook"
                            onClick={signInWithFacebook}
                        >
                            Link with Facebook
                        </Button>
                        <Button
                            type="button"
                            className="custom-login-btn-twitter"
                            onClick={signInWithTwitter}
                        >
                            Link with Twitter
                        </Button>
                    </Form.Group>
                </div>
            </div>
        </div>
    );
};
function ProfileInfo({ userInfo, showProfile, setShowProfile }) {
    const [typeData, setTypeData] = useState('');
    const [showPassChange, setShowPassChange] = useState(false);
    const [showEmailmanage, setShowEmailmanage] = useState(false);
    const [showPhonemanage, setShowPhonemanage] = useState(false);

    const changeProfileBasic = (type) => {
        if (type === 'name') {
            setTypeData(type);
        } else if (type === 'contact') {
            setTypeData(type);
        }
        setShowProfile(true);
    };

    return (
        <>
            <div>
                <NameSection userInfo={userInfo} changeProfileBasic={changeProfileBasic} />

                <EmailSection userInfo={userInfo} setShowEmailmanage={setShowEmailmanage} />

                <PhoneNumberSection userInfo={userInfo} setShowPhonemanage={setShowPhonemanage} />

                <PasswordSection userInfo={userInfo} setShowPassChange={setShowPassChange} />

                <SocialSection />
            </div>

            <UserUpdateModals
                show={showProfile}
                setShow={setShowProfile}
                typeData={typeData}
                userInfo={userInfo}
            />
            <UserPasswordChangeModal show={showPassChange} setShow={setShowPassChange} />
            <UserEmailChnageModal show={showEmailmanage} setShow={setShowEmailmanage} />
            <UserPhoneChnageModal show={showPhonemanage} setShow={setShowPhonemanage} />
        </>
    );
}

function UsersProfilePage() {
    const { currentUser } = useAuth();
    const [showProfile, setShowProfile] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_PROFILE_DATA)
            .then(({ data }) => {
                setUserInfo(data.userInfoAr);
            });
    }, [currentUser, showProfile]);

    if (!userInfo) {
        return null;
    }

    return (
        <div className="col-12 col-lg-9">
            <div className="user-profile">
                <UserProfilePageTitle />
                <ProfileInfo
                    userInfo={userInfo}
                    currentUser={currentUser}
                    showProfile={showProfile}
                    setShowProfile={setShowProfile}
                />
            </div>
        </div>
    );
}

export default UsersProfilePage;
