/* eslint-disable prettier/prettier */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserPImage from '../../../../assets/images/user.png';
import { useAuth } from '../../../contexts/AuthProvider';

function UserMenu() {
    const { currentUser } = useAuth();
    const { pathname } = useLocation();
    return (
        <div className="d-none d-lg-block d-xl-block col-lg-3 col-xl-3 pb-2">
            <div className="user-menu-section">
                <div className="user-menu-profile">
                    <div className="user-menu-profile-img">
                        <img
                            src={
                                currentUser?.photoUrl !== "" ? currentUser.photoUrl : UserPImage}
                            alt="User"
                        />
                    </div>
                    <div className="user-menu-profile-title">
                        <h3>{currentUser.displayName}</h3>
                        <p>{currentUser.phoneNumber ? currentUser.phoneNumber : currentUser.email}</p>
                    </div>
                    <div className="user-menu-profile-btn">
                        <Link to="/user/profile/">My Profile</Link>
                    </div>
                </div>
                <div className="user-menu-profile-divider" />
                <div className="user-profile-menu">
                    <Link
                        to="/user/order/"
                        className={
                            pathname === '/user/order/' ||
                                pathname === '/user/processing-order/' ||
                                pathname === '/user/delivered-order/' ||
                                pathname === '/user/canceled-order/' ||
                                pathname === '/user/order-detail/:invoiceId/'
                                ? 'user-menu2-active'
                                : 'user-menu2'
                        }
                    >
                        <div className="user-menu-order-img" />
                        <div className="user-menu-order-text">My Order</div>
                    </Link>
                    <Link
                        to="/user/wishlist/"
                        className={
                            pathname === '/user/wishlist/' ? 'user-menu2-active' : 'user-menu2'
                        }
                    >
                        <div className="user-menu-favt-img" />
                        <div className="user-menu-favt-text">My Favorite</div>
                    </Link>
                    <Link
                        to="/user/reviews/"
                        className={
                            pathname === '/user/reviews/' ? 'user-menu2-active' : 'user-menu2'
                        }
                    >
                        <div className="user-menu-review-img" />
                        <div className="user-menu-review-text">My Review</div>
                    </Link>
                    <Link
                        to="/user/support/"
                        className={
                            pathname === '/user/support/' ? 'user-menu2-active' : 'user-menu2'
                        }
                    >
                        <div className="user-menu-support-img" />
                        <div className="user-menu-support-text">Support</div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default UserMenu;
