/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-await-in-loop */
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImgPlace from '../../../../assets/images/review_place_img.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';

function ReviewStar({ currentReview, setCurrentReview }) {
    const rows = [];
    for (let i = 1; i <= 5; i += 1) {
        if (i <= currentReview) {
            rows.push(
                <FontAwesomeIcon
                    size="lg"
                    icon={faStar}
                    color="#FFC01D"
                    onClick={() => setCurrentReview(i)}
                />
            );
        } else {
            rows.push(
                <FontAwesomeIcon
                    size="lg"
                    icon={faStar}
                    color="#CFD1D5"
                    onClick={() => setCurrentReview(i)}
                />
            );
        }
    }
    return rows;
}
function UserReviewDetailPage() {
    const { products } = InitInfo();

    const { invoiceItemId } = useParams();
    const { currentUser } = useAuth();
    const [invoiceItemData, setInvoiceItemData] = useState([]);
    const [currentReview, setCurrentReview] = useState(0);
    const [images, setImages] = useState([]);
    const uploadRef = createRef();
    const refReview = useRef();

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    setImages([...images, response.data.imageUrl]);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleCreateReview = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(
                ConfigApi.API_USER_REVIEW_CREATE.replace(':invoiceItemId', invoiceItemId).replace(
                    ':invoiceId',
                    invoiceItemData.memoId
                ),
                {
                    review: refReview.current.value,
                    images,
                    point: currentReview,
                }
            )
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        // Collect API Data
        if (invoiceItemId) {
            AxiosAuth.currentUserAuth(currentUser)
                .get(ConfigApi.API_USER_REVIEW_ITEM.replace(':invoiceItemId', invoiceItemId))
                .then((response) => {
                    setInvoiceItemData(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        // Collect invoice data
    }, [currentUser, invoiceItemId]);

    const FindArr = new FindInArray();
    FindArr.init(products, 'id');
    const ItemProducts = FindArr.find(invoiceItemData.productId);

    return (
        <div className="col-12 col-lg-9">
            <div className="user-review-detail">
                <div className="row">
                    <div className="col-7">
                        <div className="user-review-detail-title">Delivered on 09 Feb 2022</div>
                        <div className="user-review-detail-info">
                            <div className="row">
                                <div className="col-2">
                                    <img
                                        src={`https://www.optimizee.xyz/images/${btoa(
                                            ItemProducts?.default_image
                                        )},200,300.jpg`}
                                        alt=""
                                    />
                                </div>
                                <div className="col-9">
                                    <h6>{invoiceItemData.product_title}</h6>
                                    <div className="review-ratting">
                                        <ReviewStar
                                            currentReview={currentReview}
                                            setCurrentReview={setCurrentReview}
                                        />
                                    </div>
                                    <div className="review-text-box">
                                        <lable>
                                            Review detail<span>How to write a good review?</span>
                                        </lable>
                                        <textarea
                                            type="text"
                                            ref={refReview}
                                            placeholder="Review detail"
                                        />
                                    </div>
                                    <div className="review-img-box">
                                        {images?.map((row) => (
                                            <img src={row} alt="" key={row} />
                                        ))}
                                        <img
                                            src={ImgPlace}
                                            alt=""
                                            onClick={() => {
                                                uploadRef.current.click();
                                            }}
                                        />

                                        <input
                                            ref={uploadRef}
                                            type="file"
                                            name="file"
                                            onChange={handleOnFileChange}
                                            multiple
                                            style={{ display: 'none' }}
                                            accept="image/png, image/jpeg,image/jpg"
                                        />
                                    </div>
                                    <div className="review-notice-box">
                                        <p>Important:</p>
                                        <ul>
                                            <li>Maximum 6 images can be uploaded</li>
                                            <li>Image size can be maximum 5mb</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-1">x2</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="user-review-detail-title">Rate your Rider:</div>
                        <div className="user-review-detail-info">
                            <div className="review-ratting">
                                <ReviewStar
                                    currentReview={currentReview}
                                    setCurrentReview={setCurrentReview}
                                />
                            </div>
                            <div className="review-text-box">
                                <lable>Review detail</lable>
                                <textarea type="text">Review detail</textarea>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <button
                            type="button"
                            onClick={handleCreateReview}
                            className="review-submit"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserReviewDetailPage;
