/* eslint-disable import/no-unresolved */
import React from 'react';

export default function ProductPropertyDescription({
    data,
    productCategoryList,
    ProductDescriptionVariant,
}) {
    return (
        <div className="row">
            <div className="col-12">
                <p>
                    Product Name : <b>{data.title}</b>
                </p>
                <p>
                    Category :
                    {data.categories &&
                        data.categories.map((cat) => (
                            <b style={{ padding: '5px' }} key={cat}>
                                {
                                    productCategoryList
                                        .filter((e) => e.categoryId === cat)
                                        .map((en) => en.view)[0]
                                }
                            </b>
                        ))}
                </p>
                <ProductDescriptionVariant
                    data={data.variation_header ? data.variation_header : []}
                    categories={data.categories}
                />

                <div
                    dangerouslySetInnerHTML={{
                        __html: data.product_description,
                    }}
                />
            </div>
        </div>
    );
}
