/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import AddMemberIcon from '../../../../assets/images/Add_Member.png';
import ConfigApi from '../../../../configs/ConfigApi';
import AddressProvider, { useAddress } from '../../../contexts/AddressProvider';
import { useAuth } from '../../../contexts/AuthProvider';
import AddressTypeBtn from '../../../utils/AddressTypeBtn';
import AxiosAuth from '../../../utils/AxiosAuth';
import UserProfilePageTitle from '../components/UserProfilePageTitle';

const AddBtn = () => {
    const { setShowAddressManager } = useAddress();
    return (
        <button
            onClick={() => {
                setShowAddressManager('add');
            }}
            style={{
                margin: 'auto',
                float: 'right',
                border: 'none',
                background: 'none',
            }}
            type="button"
        >
            <img src={AddMemberIcon} alt="Add Member" />
        </button>
    );
};
function Address({ item }) {
    const { setShowAddressManager, selectedAddress, setSelectedAddress } = useAddress();
    return (
        <tr style={{ fontSize: '12px', paddingBottom: 10 }}>
            <td style={{ paddingLeft: '20px' }}>
                <p style={{ fontWeight: 'bold' }}>{item.name}</p>
                <AddressTypeBtn type={item.resident_type} />
            </td>
            <td className="user-address-td-detail">
                {item.address?.line1},{item.address?.line2},{item.address?.city},
                {item.address?.state},<br />
                {item.address?.country},{item.address?.zip}
            </td>
            <td>
                {item.phone}
                <br />
                {item.email}
            </td>
            <td style={{ textAlign: 'center' }}>
                <button
                    onClick={() => {
                        setShowAddressManager('update');
                        setSelectedAddress(item);
                    }}
                    type="button"
                    className="user-address-edit-btn"
                >
                    Edit
                </button>
            </td>
        </tr>
    );
}

const AddressTable = ({ userAddresses }) => (
    <table className="address-modal-table">
        <thead>
            <tr
                style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                }}
            >
                <th style={{ paddingLeft: '20px' }}>Full Name</th>
                <th>Address</th>
                <th>Contact</th>
                <th colSpan="2" />
            </tr>
        </thead>
        <tbody className="user-profile-address-tbody">
            {userAddresses.map((item) => (
                <Address item={item} key={item.sl} />
            ))}
        </tbody>
    </table>
);

function UsersAddressPage() {
    const { currentUser } = useAuth();
    const [userAddresses, setUserAddresses] = useState();

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_PROFILE_DATA)
            .then(({ data }) => {
                setUserAddresses(data.userAddress_ar);
            });
    }, [currentUser]);

    if (!userAddresses) {
        return null;
    }

    return (
        <>
            <div className="col-12 col-lg-9">
                <div className="user-profile">
                    <UserProfilePageTitle />
                    <AddressProvider
                        currentUser={currentUser}
                        addressData={userAddresses}
                        setUserAddresses={setUserAddresses}
                    >
                        <div className="user-profile-address">
                            <AddressTable userAddresses={userAddresses} />

                            <AddBtn />
                        </div>
                    </AddressProvider>
                </div>
            </div>
        </>
    );
}

export default UsersAddressPage;
