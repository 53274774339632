import React, { createContext, useContext, useRef, useState } from 'react';
import iconBkash from '../../assets/images/icon/icon-brand-bkash.svg';
import iconBankCards from '../../assets/images/icon/icon-brand-card-bank.svg';
import iconGooglePay from '../../assets/images/icon/icon-brand-google-pay.svg';
import iconNagad from '../../assets/images/icon/icon-brand-nagad.svg';
import iconPaypal from '../../assets/images/icon/icon-brand-paypal.svg';
import IconCashCod from '../../assets/images/IconCashOut.svg';
import Bkash from '../gateways/Bkash';
import Sslcom from '../gateways/Sslcom';

const PaymentContext = createContext();

export function usePayment() {
    return useContext(PaymentContext);
}

const paymentOptions = [
    {
        id: 'cod',
        icon: IconCashCod,
        title: 'Cash on Delivery',
        subTitle: 'BDT, Bangladesh Only',
        btnText: 'Proceed to Cash on Delivery',
        isInit: false,
        isEnable: true,
    },
    {
        id: 'bkash',
        icon: iconBkash,
        title: 'bKash',
        subTitle: 'BDT, Bangladesh Only',
        btnText: 'Pay with bKash',
        isInit: false,
        isEnable: true,
    },
    {
        id: 'nagad',
        icon: iconNagad,
        title: 'Nagad',
        subTitle: 'BDT, Bangladesh Only',
        btnText: 'Pay with Nagad',
        isInit: false,
        isEnable: true,
    },
    {
        id: 'sslcom',
        icon: iconBankCards,
        title: 'Card or Bank',
        subTitle: 'BDT, USD International',
        btnText: 'Pay with Debit or Credit card',
        isInit: false,
        isEnable: true,
    },
    {
        id: 'paypal',
        icon: iconPaypal,
        title: 'PayPal Payment',
        subTitle: 'USD, International',
        btnText: 'Pay with PayPal',
        isInit: false,
        isEnable: false,
    },
    {
        id: 'google-pay',
        icon: iconGooglePay,
        title: 'Google Pay',
        subTitle: 'USD, International',
        btnText: 'Pay with Google Pay',
        isInit: false,
        isEnable: false,
    },
];

function PaymentProvider({ children, invoiceInfo }) {
    const [selectedPayment, setSelectedPayment] = useState(null);
    const selectedPaymentId = selectedPayment?.id;
    const bKashRef = useRef(null);
    const sslcomRef = useRef(null);

    const paymentClickHandler = () => {
        if (selectedPaymentId === 'bkash') {
            bKashRef.current.click();
        }
        if (selectedPaymentId === 'sslcom') {
            sslcomRef.current.click();
        }
    };

    const value = {
        paymentOptions,
        paymentActiveOptions: paymentOptions.filter((item) => item.isEnable),
        selectedPayment,
        setSelectedPayment,
        paymentClickHandler,
    };

    return (
        <PaymentContext.Provider value={value}>
            {children}
            {selectedPaymentId === 'bkash' ? (
                <Bkash
                    ref={bKashRef}
                    order={invoiceInfo?.id || ''}
                    amount={invoiceInfo?.totalAmountOffer || 0}
                    currency="BDT"
                />
            ) : null}
            {selectedPaymentId === 'sslcom' ? (
                <Sslcom
                    ref={sslcomRef}
                    order={invoiceInfo?.id || ''}
                    amount={invoiceInfo?.totalAmountOffer || 0}
                    currency="BDT"
                />
            ) : null}
        </PaymentContext.Provider>
    );
}

export default PaymentProvider;
