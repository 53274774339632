import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../../../contexts/CartProvider';

// function priceCalculator(cartProducts) {
//     let tItem = 0;

//     cartProducts?.forEach((itemAr) => {
//         itemAr.productVariation.forEach((variation) => {
//             tItem += variation.quantity;
//         });
//     });

//     return { totalItem: tItem };
// }

function CartOption() {
    const { totalItem } = useCart();

    const url = '/cart/';

    return (
        <div className="top-options">
            <Link to={url} className="top-icon" role="button">
                <FontAwesomeIcon icon={faShoppingCart} size="lg" className="user-operation-icon" />
                {totalItem > 0 && <span className="cart-item">{totalItem}</span>}
                <div className="header-cart-title">Cart</div>
            </Link>
        </div>
    );
}

export default CartOption;
