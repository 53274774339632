/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';

const creatingSupport = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SUPPORT_CREATE, params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

export default function UserSupportAddModal(props) {
    const { supportDepartment, show, setShow } = props;
    const { setMessage } = useTemplate();

    const [subject, setSubject] = useState('');
    const [sDepartemnt, setSDepartemnt] = useState('10000001');
    const [ticket, setTicket] = useState('');
    const [relatedTopic, setRelatedTopic] = useState('delivery');
    const [attachment, setAttachment] = useState('');
    const [isUrgent, setIsUrgent] = useState(0);

    const handleChangeValue = (event) => {
        if (event.target.name === 'subject') {
            setSubject(event.target.value);
        }
        if (event.target.name === 'sDepartment') {
            setSDepartemnt(event.target.value);
        }
        if (event.target.name === 'ticket') {
            setTicket(event.target.value);
        }
        if (event.target.name === 'relatedTopic') {
            setRelatedTopic(event.target.value);
        }
        if (event.target.name === 'attachment') {
            setAttachment(event.target.value);
        }
        if (event.target.name === 'isUrgent') {
            setIsUrgent(event.target.value === 'on' ? 1 : 0);
        }
    };

    const handleNextClick = () => {
        creatingSupport({
            subject,
            sDepartemnt,
            ticket,
            relatedTopic,
            attachment,
            isUrgent,
        })
            .then((response) => {
                setMessage(response.data.message);
                if (response.data.error === 0) {
                    setShow(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    setAttachment(response.data.imageUrl);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => {
                    setShow(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton closeVariant="custom">
                    <Modal.Title>Support Ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="row">
                        <div className="col-12">
                            <small>
                                For technical related tickets,please make sure to include all the
                                details which might be useful for our support team in handling your
                                ticket.{' '}
                            </small>
                        </div>

                        <div className="col-12 col-lg-6">
                            <Form.Group className="custom-login-input">
                                <Form.Label className="custom-label">Subject</Form.Label>
                                <input
                                    className="form-control"
                                    name="subject"
                                    type="text"
                                    placeholder="Enter Subject"
                                    value={subject}
                                    onChange={handleChangeValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Group className="custom-login-input">
                                <Form.Label className="custom-label">Department</Form.Label>

                                <Form.Select
                                    name="sDepartment"
                                    value={sDepartemnt}
                                    onChange={handleChangeValue}
                                >
                                    <option>Select Department</option>
                                    {supportDepartment &&
                                        supportDepartment?.map((support) => (
                                            <option value={support.id} key={support.id}>
                                                {support.department}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group className="custom-login-input">
                                <Form.Label className="custom-label">Ticket</Form.Label>
                                <textarea
                                    className="form-control"
                                    name="ticket"
                                    type="text"
                                    placeholder="Enter Ticket Detail"
                                    value={ticket}
                                    onChange={handleChangeValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Group className="custom-login-input">
                                <Form.Label className="custom-label">Related Topics</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="relatedTopic"
                                    value={relatedTopic}
                                    onChange={handleChangeValue}
                                >
                                    <option value="delivery" key={1}>
                                        Delivery Issue
                                    </option>
                                    <option value="paymnet" key={2}>
                                        Payment Issue
                                    </option>
                                    <option value="refund" key={3}>
                                        Refund Issue
                                    </option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Group className="custom-login-input">
                                <Form.Label className="custom-label">Attachment</Form.Label>
                                <br />
                                <input
                                    type="file"
                                    name="file"
                                    onChange={handleOnFileChange}
                                    multiple
                                    accept="image/png, image/jpeg,application/pdf"
                                />
                            </Form.Group>
                        </div>

                        <div className="col-12 col-lg-6">
                            <Form.Group className="custom-login-input">
                                <Form.Check
                                    onChange={handleChangeValue}
                                    name="isUrgent"
                                    type="checkbox"
                                    label="Urgent Response Required !"
                                    className="custom-label"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNextClick}
                            >
                                Submit
                            </button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
