import React, { createContext, useContext, useState } from 'react';
import ConfigApi from '../../configs/ConfigApi';
import UserAddressModal from '../app/users/components/UserAddressAddModal';
import UserAddressListModal from '../app/users/components/UserAddressListModal';
import AxiosAuth from '../utils/AxiosAuth';

const AuthContext = createContext();

export function useAddress() {
    return useContext(AuthContext);
}

export default function AddressProvider({
    currentUser,
    addressData,
    children,
    isBackIcon = false,
    setUserAddresses,
}) {
    // Todo State will be updated
    const { addresses: dbAddresses, defaultAddress } = addressData;
    const [addresses, setAddresses] = useState(dbAddresses);
    const [showAddressManage, setShowAddressManager] = useState(null); // add, list, update
    const [selectedAddress, setSelectedAddress] = useState(defaultAddress);

    const addAddress = (params) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .post(ConfigApi.API_USER_ADDRESS_CREATE, params)
                .then(({ data }) => {
                    if (data.error === 0) {
                        setAddresses(data.addresses);
                        setSelectedAddress(data.selectedAddress);
                        if (typeof setUserAddresses === 'function') {
                            setUserAddresses(data.addresses);
                        }
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const updateAddress = (params) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .post(
                    ConfigApi.API_USER_ADDRESS_UPDATE.replace(':addressId', params.addressId),
                    params
                )
                .then(({ data }) => {
                    if (data.error === 0) {
                        setAddresses(data.addresses);
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const value = {
        showAddressManage,
        setShowAddressManager,
        selectedAddress,
        setSelectedAddress,
        addAddress,
        updateAddress,
        addresses,
        defaultAddress,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
            {showAddressManage === 'add' && (
                <UserAddressModal
                    selectedAddress={{}}
                    setSelectedAddress={setSelectedAddress}
                    addAddress={addAddress}
                    updateAddress={updateAddress}
                    setShowAddressManager={setShowAddressManager}
                    isBackIcon={isBackIcon}
                />
            )}
            {showAddressManage === 'update' && (
                <UserAddressModal
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    addAddress={addAddress}
                    updateAddress={updateAddress}
                    setShowAddressManager={setShowAddressManager}
                    isBackIcon={isBackIcon}
                />
            )}
            {showAddressManage === 'list' && (
                <UserAddressListModal
                    show
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    addresses={addresses}
                    setShowAddressManager={setShowAddressManager}
                />
            )}
        </AuthContext.Provider>
    );
}
