import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NoProductImg from '../../../../assets/images/NoProduct2.png';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import UserOrdersSection from '../components/UserOrdersSection';

function UserOrderTitleBar({ cattype }) {
    return (
        <div className="user-order-title">
            <div className="user-order-title-view">
                <Link to="/user/order" className={cattype === 'all' ? 'user-order-btn-active' : ''}>
                    All Orders
                </Link>
                <Link
                    to="/user/processing-order"
                    className={cattype === 'processing' ? 'user-order-btn-active' : ''}
                >
                    In Processing
                </Link>
                <Link
                    to="/user/delivered-order"
                    className={cattype === 'delivered' ? 'user-order-btn-active' : ''}
                >
                    Delivered
                </Link>
                <Link
                    to="/user/canceled-order"
                    className={cattype === 'canceled' ? 'user-order-btn-active' : ''}
                >
                    Canceled
                </Link>
            </div>
        </div>
    );
}

function UsersOrderPage({ type }) {
    const { currentUser } = useAuth();
    const { products } = InitInfo();

    const [memoAr, setMemoAr] = useState([]);
    const [memoItemAr, setMemoItemAr] = useState([]);

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_USER_ORDERS.replace(':type', type))
            .then((response) => {
                setMemoAr(response.data.memoAr);
                setMemoItemAr(response.data.memoItemAr);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, type]);

    return (
        <div className="col-12 col-lg-9">
            <div className="user-order">
                <UserOrderTitleBar cattype={type} />

                {memoAr.length !== 0 ? (
                    memoAr &&
                    memoAr.map((item) => {
                        const memoItem = memoItemAr.filter(
                            (mItem) => mItem.memoId.toString() === item.id.toString()
                        );

                        return (
                            <div className="user-order-section" key={item.id}>
                                <div>
                                    <UserOrdersSection
                                        memoData={item}
                                        memoItemData={memoItem}
                                        products={products}
                                    />
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="order-section-no-order">
                        <div className="order-section-no-order-title">
                            <h5>There Are No Orders.</h5>
                            <h6>You can review and track your orders here.</h6>
                        </div>
                        <div className="order-section-no-order-img">
                            <img src={NoProductImg} alt="No Product" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UsersOrderPage;
