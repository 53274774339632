import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import InvoicePageSection from '../components/InvoicePageSection';

const getInvoiceData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_INVOICE, {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function InvoicePage() {
    // const { products } = InitInfo();
    const { products } = InitInfo();
    const dispatch = useDispatch();
    const [invoiceItemData, setInvoiceItemData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);

    const [allInvoice, setAllInvoice] = useState([]);
    const history = useNavigate();
    const { invoiceId } = useParams();
    const isLogin = localStorage.getItem('Secure-Access');
    const [addressAr, setAddressAr] = useState([]);

    useEffect(() => {
        // Collect API Data

        // Collect invoice data
        getInvoiceData({ invoiceId })
            .then((response) => {
                setInvoiceData(response.data.invoice);
                setInvoiceItemData(response.data.invoiceItem);
                setAllInvoice(response.data.allInvoice);
                setAddressAr(response.data.addressAr);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, history, invoiceId, isLogin]);

    if (!invoiceData) {
        return null;
    }
    return (
        <InvoicePageSection
            productData={products}
            invoiceData={invoiceData}
            invoiceItemData={invoiceItemData}
            allInvoice={allInvoice}
            address={addressAr}
        />
    );
}

export default InvoicePage;
