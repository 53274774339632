import React, { useEffect, useState } from 'react';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import ProductItem from '../../products/components/ProductItem';

function OrderDetailRelatedProduct({ products, wishList }) {
    const FindArr = new FindInArray();
    FindArr.init(products, 'id');
    return (
        <div className="row">
            {wishList?.map((wishId) => {
                const itemData = FindArr.find(wishId);

                return (
                    <div className="col-6 col-lg-4 mb-4" key={wishId}>
                        <ProductItem itemData={itemData} />
                    </div>
                );
            })}
        </div>
    );
}
function UserWishlistPage() {
    const { products } = InitInfo();
    const [wishList, setWishList] = useState();
    const { currentUser } = useAuth();

    useEffect(() => {
        if (currentUser) {
            AxiosAuth.currentUserAuth(currentUser)
                .get(ConfigApi.API_USER_WISHLIST)
                .then((response) => {
                    setWishList(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [currentUser]);
    return (
        <div className="col-12 col-lg-9">
            <div className="order-section-detail-wishlist">
                <OrderDetailRelatedProduct products={products} wishList={wishList} />
            </div>
        </div>
    );
}

export default UserWishlistPage;
