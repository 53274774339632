import React from 'react';
import InitInfo from '../../../utils/InitInfo';
import CartPageSection from '../components/CartPageSection';

function CartPage() {
    const { products } = InitInfo();

    return <CartPageSection products={products} />;
}

export default CartPage;
