/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import AddMemberIcon from '../../../../assets/images/Add_Member.png';
import UserPImage from '../../../../assets/images/user.png';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import FamilyAddModal from '../components/FamilyAddModal';
import UserProfilePageTitle from '../components/UserProfilePageTitle';

const PlusButton = ({ setShow }) => (
    <div className="col-12">
        <button
            onClick={() => {
                setShow(true);
            }}
            style={{
                margin: 'auto',
                float: 'right',
                background: 'none',
                border: 'none',
            }}
            type="button"
        >
            <img src={AddMemberIcon} alt="Add Member" />
        </button>
    </div>
);

const FamilyCart = ({ item, setShow, setSelectFamilyMember }) => (
    <div className="col-12 col-lg-6 mb-4">
        <div className="user-profile-family-member">
            <div className="row">
                <div className="col-4">
                    <div className="user-profile-family-member-img">
                        <img src={UserPImage} alt="User" />
                    </div>
                    <div className="user-profile-family-member-type">{item.relation}</div>
                </div>
                <div className="col-4 pl-0">
                    <div className="user-profile-family-member-detail">Name</div>
                    <div className="user-profile-family-member-value">{item.name}</div>
                    <div className="user-profile-family-member-detail mt-4">Contact</div>
                    <div className="user-profile-family-member-value">{item.phone}</div>
                </div>
                <div className="col-4 pl-0">
                    <div className="user-profile-family-member-detail">Date of Birth</div>
                    <div className="user-profile-family-member-detail mb-4">{item.birth_date}</div>

                    <button
                        type="button"
                        onClick={() => {
                            setShow(true);
                            setSelectFamilyMember(item);
                        }}
                        className="user-address-edit-btn mt-1"
                    >
                        Edit
                    </button>
                </div>
            </div>
        </div>
    </div>
);

function UsersFamilyPage() {
    const { currentUser } = useAuth();
    const [userFamily, setUserFamily] = useState([]);
    const [show, setShow] = useState(false);
    const [selectFamilyMember, setSelectFamilyMember] = useState(null);

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_PROFILE_DATA)
            .then(({ data }) => {
                setUserFamily(data.familyMember_all_ar);
            });
    }, [currentUser, show]);

    return (
        <div className="col-12 col-lg-9">
            <div className="user-profile">
                <UserProfilePageTitle />
                <div className="user-profile-family">
                    <div className="row">
                        {userFamily.length > 0
                            ? userFamily.map((item) => (
                                <FamilyCart
                                    item={item}
                                    key={item.sl}
                                    setShow={setShow}
                                    setSelectFamilyMember={setSelectFamilyMember}
                                />
                            ))
                            : null}

                        <PlusButton setShow={setShow} />
                    </div>
                </div>

                <FamilyAddModal
                    show={show}
                    setShow={setShow}
                    item={selectFamilyMember}
                    setSelectFamilyMember={setSelectFamilyMember}
                />
            </div>
        </div>
    );
}

export default UsersFamilyPage;
