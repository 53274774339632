import React, { forwardRef, useEffect } from 'react';
import ConfigApi from '../../configs/ConfigApi';
import { useAuth } from '../contexts/AuthProvider';
import InitInfo from '../utils/InitInfo';

let loaded = false;

function InitSslcom() {
    if (!loaded) {
        const script = document.createElement('script');
        const tag = document.getElementsByTagName('script')[0];
        script.src = `https://sandbox.sslcommerz.com/embed.min.js?${Math.random()
            .toString(36)
            .substring(7)}`;
        tag.parentNode.insertBefore(script, tag);
        loaded = true;
    }
}

const Sslcom = forwardRef((props, ref) => {
    const { order } = props; // , amount, currency
    const { currentUser } = useAuth();
    const { initId, shopId } = InitInfo();

    useEffect(() => {
        InitSslcom();
        document.querySelector(
            '#sslczPayBtn'
        ).token = `${currentUser.localUid}:::${currentUser.refreshToken}:::${initId}:::${shopId}`;
    }, [currentUser.localUid, currentUser.refreshToken, currentUser.uid, initId, shopId]);

    return (
        <button
            type="button"
            style={{ display: 'none' }}
            id="sslczPayBtn"
            ref={ref}
            order={order}
            endpoint={ConfigApi.SSLCOM_ENDPOINT}
        />
    );
});

export default Sslcom;
