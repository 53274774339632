import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
    faBars,
    faMagnifyingGlass,
    faPhone,
    faSearch,
    // eslint-disable-next-line prettier/prettier
    faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Container, FormControl, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import RupLogoWhite from '../../../../assets/images/Rup-logo-white.svg';
import RupLogo from '../../../../assets/images/Rup-logo.svg';
import { useAuth } from '../../../contexts/AuthProvider';
import store from '../../../store';
import InitInfo from '../../../utils/InitInfo';
import CartOption from './CartOption';
import HeaderSideBarMenu from './HeaderSideBarMenu';
import MenuPrimary from './MenuPrimary';
import UserOption from './UserOption';

function SearchOption({ setMSdisplay }) {
    const [searchParams] = useSearchParams();
    const searchItem = searchParams.get('k');
    const [searchText, setSearchText] = useState(searchItem !== null || '');
    const [hovered, setHovered] = useState(false);
    const [focused, setFocused] = useState(false);
    const history = useNavigate();

    let searchClassName = 'search-box';
    if (hovered || focused) {
        searchClassName = 'search-box obj-focused';
    }
    const handleSearchChange = (ev) => {
        setSearchText(ev.target.value);
    };
    const handleSearch = () => {
        setMSdisplay(false);
        history(`/search/?k=${searchText}`);
    };

    return (
        <InputGroup
            className={searchClassName}
            onMouseEnter={() => {
                setHovered(true);
            }}
            onMouseLeave={() => {
                setHovered(false);
            }}
        >
            <FormControl
                placeholder="Search Here"
                style={{
                    borderRight: 'none',
                }}
                className="search-form-control"
                value={searchText}
                onChange={handleSearchChange}
                onFocus={() => {
                    setFocused(true);
                }}
                onBlur={() => {
                    setFocused(false);
                }}
            />
            <FontAwesomeIcon
                icon={faXmark}
                onClick={() => {
                    setSearchText('');
                }}
                className="searchInputCross"
                style={{
                    display: searchText !== '' ? 'block' : 'none',
                }}
            />
            <InputGroup.Text
                style={{
                    backgroundColor: '#666666',
                    border: 'none',
                }}
            >
                <FontAwesomeIcon
                    icon={faSearch}
                    onClick={() => {
                        handleSearch();
                    }}
                    onChange={() => {
                        handleSearch();
                    }}
                    cursor="pointer"
                    style={{
                        color: 'white',
                    }}
                />
            </InputGroup.Text>
        </InputGroup>
    );
}

function MenuHeader({ setShowLoginModal, isUserPage, logo, iconColor }) {
    const { categories } = store.getState().shopData;
    const [showSideMenu, setShowSideMenu] = useState(false);
    const [mSdisplay, setMSdisplay] = useState(false);
    return (
        <div className="menu-header">
            <Container>
                <div className="row align-items-center">
                    <div
                        className="col-12 mobile-search-none"
                        style={{ position: 'absolute', display: mSdisplay ? 'block' : 'none' }}
                    >
                        <SearchOption setMSdisplay={setMSdisplay} />
                    </div>
                    <div className="col-2 m-menu-bar">
                        <FontAwesomeIcon
                            icon={faBars}
                            color={iconColor === 'yes' ? 'white' : 'black'}
                            onClick={() => {
                                setShowSideMenu(true);
                            }}
                        />
                    </div>

                    <div className="col-7 col-lg-6">
                        <div className="menu-header-left">
                            <Link to="/">
                                <img className="brand-logo" src={logo} alt="" />
                            </Link>
                            <div className="responsive-menu-view">
                                <MenuPrimary MenuData={categories} />
                            </div>
                        </div>
                    </div>

                    <div
                        className="col-3 col-lg-4 align-items-center"
                        style={{ display: mSdisplay ? 'none' : 'block' }}
                    >
                        <div className="m-search-bar text-center">
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                color={iconColor === 'yes' ? 'white' : 'black'}
                                onClick={() => {
                                    setMSdisplay(true);
                                }}
                            />
                        </div>

                        <div className="responsive-option-view">
                            <SearchOption setMSdisplay={setMSdisplay} />
                        </div>
                    </div>

                    <div className="col-lg-2 d-none d-lg-block">
                        <UserOption setShowLoginModal={setShowLoginModal} isUserPage={isUserPage} />
                        <CartOption />
                    </div>
                </div>
            </Container>
            <HeaderSideBarMenu
                categories={categories}
                show={showSideMenu}
                setShowSideMenu={setShowSideMenu}
            />
        </div>
    );
}

function TopHeaderArea({ shopData }) {
    return (
        <div className="top-header">
            <Container>
                <div className="row">
                    <div className="col-6 social-links">
                        <a
                            href="https://www.facebook.com/Rupkatha.com.bd"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>

                        <a
                            href="https://twitter.com/rupkath85231111?fbclid=IwAR2rOQJ_Y0HyNCgOHNJKAlOG_eDmmfPCftoZxSpG9jEeomjg40rmFZavaSg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>

                        <a
                            href="https://www.youtube.com/channel/UC-56AWPhbB_bGp7HpTgVxeQ"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>

                        <a
                            href="https://www.instagram.com/rupkatha.ia/?fbclid=IwAR1oz7nRpTsTxt4qEfocpM_VtYu8Udfiw4Ich_qbKRXM3efFOkPJp6Wcxq4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                    <div className="col-6 helpline-box">
                        <span className="helpline">
                            {shopData?.shopInfo?.contact_number ? (
                                <a
                                    href={`tel:${shopData.shopInfo.contact_number}`}
                                    style={{ color: '#FF0000' }}
                                >
                                    <FontAwesomeIcon icon={faPhone} />{' '}
                                    {shopData.shopInfo.contact_number}
                                </a>
                            ) : (
                                'Contact Not Set'
                            )}
                        </span>
                    </div>
                </div>
            </Container>
        </div>
    );
}

function classNameControl(top, whiteMenu) {
    const newClassList = ['menu-section'];

    if (top > 10) {
        newClassList.push();

        newClassList.push('white');
        newClassList.push('position-higher');
    } else {
        newClassList.push('position-lower');
    }

    if (whiteMenu) {
        if (newClassList.indexOf('white') === -1) {
            newClassList.push('white');
        }
    }
    return newClassList;
}

function logoControl(top, whiteMenu) {
    if (top > 10 || whiteMenu) {
        return RupLogo;
    }
    return RupLogoWhite;
}

function iconControl(top, whiteMenu) {
    if (top > 10 || whiteMenu) {
        return 'no';
    }
    return 'yes';
}

function HeaderSection({ whiteMenu }) {
    const { pathname } = useLocation();
    const { isSignIn } = useAuth();
    const headerRef = useRef(null);
    const { shopData } = InitInfo();
    const isUserPage = pathname.split('/user').length > 1;
    const [logo, setLogo] = useState('');
    const [iconColor, seIconColor] = useState('');

    useEffect(() => {
        if (headerRef?.current) {
            headerRef.current.className = classNameControl(0, whiteMenu).join(' ');
            setLogo(logoControl(0, whiteMenu));
            seIconColor(iconControl(0, whiteMenu));
        }

        const onScroll = (e) => {
            const topPosition = e.target.documentElement.scrollTop;

            if (headerRef?.current) {
                headerRef.current.className = classNameControl(topPosition, whiteMenu).join(' ');
                setLogo(logoControl(topPosition, whiteMenu));
                seIconColor(iconControl(topPosition, whiteMenu));
            }
        };
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [whiteMenu]);

    return (
        <section className="menu-section" ref={headerRef}>
            <TopHeaderArea shopData={shopData} />
            <MenuHeader
                setShowLoginModal={isUserPage && !isSignIn}
                isUserPage={isUserPage}
                logo={logo}
                iconColor={iconColor}
            />
        </section>
    );
}

export default HeaderSection;
