import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import PaymentProvider from '../../../contexts/PaymentProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PaymentPagePageSection from '../components/PaymentPagePageSection';

const getInvoiceData = ({ currentUser, invoiceId }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_INVOICE.replace(':invoiceId', invoiceId))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function PaymentPage() {
    const { products } = InitInfo();
    const { currentUser } = useAuth();
    const [invoiceData, setInvoiceData] = useState([]);

    const { invoiceId } = useParams();
    useEffect(() => {
        // Collect invoice data
        getInvoiceData({ currentUser, invoiceId })
            .then((response) => {
                setInvoiceData(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, invoiceId]);

    if (invoiceData.error !== 0) {
        return <div>Invalid Invoice Information</div>;
    }

    return (
        <PaymentProvider invoiceInfo={invoiceData.invoiceInfo}>
            <PaymentPagePageSection
                products={products}
                currentUser={currentUser}
                invoiceData={invoiceData}
            />
        </PaymentProvider>
    );
}

export default PaymentPage;
