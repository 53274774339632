import React from 'react';
import { Container } from 'react-bootstrap';
import '../../../../assets/effects/EffectTwoThumb.css';
import ProductOverviewCard from './productdetails/ProductOverviewCard';
import ProductPropertyCard from './productdetails/ProductPropertyCard';

function ProductDetailSection({ productData, productCategoryList, thumbImages, shopId }) {
    return (
        <section className="product-detail-section">
            <Container>
                <ProductOverviewCard
                    data={productData}
                    productCategoryList={productCategoryList || []}
                    shopId={shopId}
                />

                <ProductPropertyCard
                    data={productData}
                    productCategoryList={productCategoryList || []}
                    thumbImages={thumbImages}
                />
            </Container>
        </section>
    );
}
export default ProductDetailSection;
