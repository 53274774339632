const api = process.env.REACT_APP_API_URL;

const ConfigApi = {
    API_APP_INIT: `${api}/application/init`,

    API_SITE_DATA: `${api}/rest/site-data.json`,
    // --Image Upload
    API_IMAGE_UPLOAD: `${api}/application/image/upload`,

    // Login Page
    API_LOGIN: `${api}/bikiran/auth/`,
    API_REGISTRATION: `${api}/bikiran/auth/registration/`, // send-otp/phone
    API_RESET_PASS: `${api}/bikiran/auth/reset-password/`, // verify-contact, verify-otp, set-password

    // API_REG_IN: `${api}/reg-user/`,
    // API_LOGGING_IN: `${api}/login-user/`,

    // API_LOGIN_EMAIL_CHK: `${api}/login/email/check/`,
    // API_LOGIN_EMAIL_BY_PASS: `${api}/login/email/by-pass/`,
    // API_LOGIN_EMAIL_OTP: `${api}/login/email/otp/`,
    // API_LOGIN_EMAIL_BY_OTP: `${api}/login/email/by-otp/`,

    // API_LOGIN_PHONE: `${api}/login/phone/`,
    // API_LOGIN_PHONE_OTP: `${api}/login/phone/otp/`,
    // API_LOGIN_PHONE_BY_OTP: `${api}/login/phone/by-otp/`,
    // API_LOGIN_PHONE_BY_PASS: `${api}/login/phone/by-pass/`,

    // Product Module
    API_PRODUCTS: `${api}/products/list/`,
    API_PRODUCT_DETAILS: `${api}/application/product/:productId/info/`,
    API_PRODUCT_TITLE_UPDATE: `${api}/products/:productId/update/title/`,
    API_PRODUCT_CAT_UPDATE: `${api}/products/:productId/update/category/`,
    API_PRODUCT_DES_UPDATE: `${api}/products/:productId/update/description/`,
    API_PRODUCT_PRICE_UPDATE: `${api}/products/:productId/update/price/`,
    API_PRODUCT_IMG_UPDATE: `${api}/products/:productId/update/images/`,
    API_PRODUCT_VARIATION_HEAD_UPDATE: `${api}/products/:productId/update/variation-header/`,
    API_PRODUCT_WISHLIST: `${api}/application/activity/user/:productId/wishlist/`,
    // --Category Paths
    API_CATEGORY_LIST: `${api}/products/category/`,

    // Cart  Module
    API_CART: `${api}/application/activity/cart/info/`,
    API_CART_STATE_UPDATE: `${api}/application/activity/cart/state-update/`,

    // Invoice Module
    API_INVOICE: `${api}/bikiran/cart/invoice/:invoiceId/data/`,
    API_INVOICE_CREATE: `${api}/bikiran/cart/invoice/create/`,

    // User Module
    API_USER_UPDATE_NAME: `${api}/application/activity/user/update-name/`,
    API_USER_UPDATE_EMAIL: `${api}/application/activity/user/update-email/`,
    API_USER_UPDATE_CONTACT: `${api}/application/activity/user/update-contact/`,
    API_USER_UPDATE_PASSWORD: `${api}/application/activity/user/update-password/`,
    API_PROVIDER_DATA: `${api}/application/activity/user/provider/data/`,
    API_PROVIDER_PHONE_DATA: `${api}/application/activity/user/provider-phone/data/`,

    API_USER_ADD_EMAIL_OTP: `${api}/application/activity/user/add-email/otp/`,
    API_USER_VERIFY_EMAIL_OTP: `${api}/application/activity/user/verify-email/otp/`,
    API_USER_DEFAULT_EMAIL_CHANGE: `${api}/application/activity/user/default-email/change/`,
    API_USER_DEFAULT_EMAIL_REMOVE: `${api}/application/activity/user/email/remove/`,

    API_USER_ADD_PHONE_OTP: `${api}/application/activity/user/add-phone/otp/`,
    API_USER_VERIFY_PHONE_OTP: `${api}/application/activity/user/verify-phone/otp/`,
    API_USER_DEFAULT_PHONE_CHANGE: `${api}/application/activity/user/default-phone/change/`,
    API_USER_DEFAULT_PHONE_REMOVE: `${api}/application/activity/user/phone/remove/`,

    API_USER_ADDRESS: `${api}/application/activity/address/`,
    API_USER_ADDRESS_CREATE: `${api}/application/activity/address/add/`,
    API_USER_ADDRESS_UPDATE: `${api}/application/activity/address/:addressId/update/`,
    API_USER_ADDRESS_DEFAULT_UPDATE: `${api}/application/activity/address/:addressId/default/update/`,
    API_USER_FAMILY: `${api}/application/activity/family/`,
    API_USER_ADD_FAMILY: `${api}/application/activity/add/family/`,
    API_USER_UPDATE_FAMILY: `${api}/application/activity/update/:familyId/family/`,
    API_USER_WISHLIST: `${api}/application/activity/user/wishlist/`,

    // Order Module
    API_USER_ORDERS: `${api}/application/activity/orders/:type/`,
    API_USER_ORDERS_RECENT: `${api}/application/activity/orders/recent/`,

    // Support Module
    API_SUPPORT: `${api}/application/activity/user/support/`,
    API_SUPPORT_CREATE: `${api}/application/activity/user/support/create/`,

    API_SUPPORT_TICKET_MESSAGE: `${api}/application/activity/user/support/ticket/:ticketId/message/`,
    API_SUPPORT_TICKET_MESSAGE_CREATE: `${api}/application/activity/user/support/ticket/message/create/`,
    API_SUPPORT_TICKET_COMPLETE: `${api}/application/activity/user/support/ticket/:ticketId/complete/`,

    // Profile Module
    API_PROFILE_DATA: `${api}/profile/info/`,
    API_PROFILE_UPDATE: `${api}/profile/info/:type/update/`,

    // Wishlist Module
    API_WISHLIST_CREATE: `${api}/application/activity/user/wishlist/:productSl/create/`,

    // Payment Gateways
    SSLCOM_ENDPOINT: `${api}/bikiran/payments/sslcom-payment/endpoint/`,

    // Subscribe Module
    API_EMAIL_SUBSCRIBE: `${api}/application/activity/user/subscribe/create/`,

    // Review Module
    API_USER_REVIEW: `${api}/application/activity/user/:type/review/`,
    API_USER_REVIEW_ITEM: `${api}/application/activity/user/:invoiceItemId/item/review/`,
    API_USER_REVIEW_CREATE: `${api}/application/activity/user/:invoiceItemId/:invoiceId/review/create/`,
};

export default ConfigApi;
