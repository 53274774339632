/* eslint-disable global-require */
import { faCartArrowDown, faSortDown, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BuyNowButton from '../../../utils/BuyNowButton';
import CustomBadge from '../../../utils/CustomBadge';
import ProductVariantModal from './ProductVariantModal';

function Rating({ color, detail }) {
    const handleRatingDetail = () => {
        //
    };

    return (
        <>
            <FontAwesomeIcon icon={faStar} color={color || '#E5E6E8'} />
            <FontAwesomeIcon icon={faStar} color={color || '#E5E6E8'} />
            <FontAwesomeIcon icon={faStar} color={color || '#E5E6E8'} />
            <FontAwesomeIcon icon={faStar} color={color || '#E5E6E8'} />
            <FontAwesomeIcon icon={faStar} color={color || '#E5E6E8'} />
            {detail === true && (
                <FontAwesomeIcon icon={faSortDown} onMouseOver={handleRatingDetail} />
            )}
        </>
    );
}

function BuyNowWithVariation({ myClassName, icon, title, size, productData }) {
    const [modalShow, setModalShow] = useState(false);

    const handleModalClose = () => setModalShow(false);

    const handleModalShow = () => {
        setModalShow(true);
    };

    return (
        <>
            <Button
                variant="outline-info"
                className={[myClassName || 'ThemeBtn'].join(' ')}
                size={size || 'sm'}
                onClick={handleModalShow}
            >
                {icon && <FontAwesomeIcon icon={icon} />}
                {icon ? ' ' : ''} {title || 'Button'}
            </Button>

            {modalShow && (
                <ProductVariantModal
                    modalShow={modalShow}
                    handleModalClose={handleModalClose}
                    productData={productData}
                />
            )}
        </>
    );
}

function ProductItem({ itemData }) {
    return (
        <Card className="product-section-card">
            <CustomBadge type="new" />
            {itemData.stock <= 0 ? (
                <img
                    className="RubberStamp"
                    src={require('../../../../assets/images/stock_out.png').default}
                    alt=""
                />
            ) : null}

            <Link to={itemData.url}>
                <Card.Img
                    className="product-section-imgBox"
                    src={`https://www.optimizee.xyz/images/${btoa(
                        itemData.default_image
                    )},200,300.jpg`}
                />
            </Link>

            <Card.Body className="product-section-card-body">
                <Card.Text className="product-section-title-text">
                    <Link to={itemData.url}>{itemData.title}</Link>
                </Card.Text>
                <div className="product-section-pricing">
                    <strong>৳ {itemData.priceOffer}</strong>
                    {itemData.price !== itemData.priceOffer ? <span>৳ {itemData.price}</span> : ''}
                    <div
                        className="product-section-rating"
                        style={{ width: itemData.price !== itemData.priceOffer ? '50%' : '75%' }}
                    >
                        <Rating />
                    </div>
                    {itemData.variation_header !== null ? (
                        <BuyNowWithVariation
                            myClassName="product-section-buyNow"
                            icon={faCartArrowDown}
                            size="sm"
                            title="ADD TO CART"
                            productId={itemData.id}
                            productData={itemData}
                        />
                    ) : (
                        <BuyNowButton
                            myClassName="product-section-buyNow"
                            icon={faCartArrowDown}
                            size="sm"
                            title="ADD TO CART"
                            productId={itemData.id}
                            selectedVariation={{}}
                            isDisable={false}
                            shopId={itemData.shopSl}
                            newQuantity={1}
                        />
                    )}
                </div>
            </Card.Body>
        </Card>
    );
}

export default ProductItem;
