import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';

function UsersLogoutPage() {
    const { signOut } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        signOut();
        navigate('/');
    });

    return <div />;
}

export default UsersLogoutPage;
