import React from 'react';
import { Container } from 'react-bootstrap';
import ProductItem from '../../products/components/ProductItem';
// import ExclusiveProduct from '../comp/ExclusiveProduct';

function ExclusiveProductSection({ products }) {
    return (
        <section className="excl-product-section">
            <Container>
                <h4 className="custom-typo">
                    <span>Exclusive</span>
                </h4>
                <div className="row row-m-product">
                    {products &&
                        products
                            // .filter((product) => product.is_exclusive === 1)
                            .slice(0, 10)
                            .map((item) => (
                                <div
                                    className="col mb-4 col-m-product excl-product-section-colI5"
                                    key={item.id}
                                >
                                    <ProductItem itemData={item} />
                                </div>
                            ))}
                </div>
            </Container>
        </section>
    );
}

export default ExclusiveProductSection;
